import React from "react";
import MetaTags from "react-meta-tags";

import BreadCrumb from "./BreadCrumb";
import Menu from "../Pages/Menu";
import cocoDining from "../Asserts/coconutImagesWebp/coco(95).webp";
const Dinning = () => {
  return (
    <>
      <MetaTags>
        <title>Best Hotel In Nashik | Coconut County Resort</title>
        <meta title="Best Hotel In Nashik | Coconut County Resort" />
        <meta
          name="description"
          content="Discover unparalleled luxury and comfort at Coconut County Resort, acclaimed as the Best Hotel in Nashik for its exquisite hospitality."
        />
        <meta
          name="keywords"
          content="Party Hall in Kandivali,Best Banquet in Kandivali,Party Hall For Events in Kandivali,Best Party Hall in Kandivali For Weddings,Banquet for Engagement in Kandivali,Birthday Party Hall in Kandivali,Party Hall in Kandivali for Naming Ceremony, Baby Shower Venues & Halls near Kandivali , Mehendi venues in Kandivali,Top Party Hall in Kandivali for Sangeet,Party Hall for Corporate Event in Kandivali,Party Hall with Stay in Kandivali,Best Banquet Hall in Kandivali,Top Banquet near Kandivali,Banquet Hall with Homestay in Kandivali,Banquet Halls with Catering in Kandivali,Banquet Hall For Social Events in Kandivali,Best Banquet near Kandivali"
        />
        <link rel="canonical" href="https://coconutcounty.in/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content="Best Hotel In Nashik | Coconut County Resort"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://coconutcounty.in/" />
        <meta
          property="og:description"
          content="Discover unparalleled luxury and comfort at Coconut County Resort, acclaimed as the Best Hotel in Nashik for its exquisite hospitality."
        />
        <meta
          property="og:image"
          content="https://coconutcounty.in/static/media/JMS.7493f202fc387288d7c0.png"
        />
      </MetaTags>
      <BreadCrumb pageName="Best Hotel In Nashik" slugs="Dining" />
      <section className="container clearfix common-pad-inner about-info-box">
        <div className="row" bis_skin_checked={1}>
          <div className="col-lg-12 col-md-12 col-xs-12" bis_skin_checked={1}>
            <div className="sec-header3" bis_skin_checked={1}>
              <h2>Our Restaurant</h2>
              <h3>Pick a room that best suits your taste and budget</h3>
            </div>
            <p>
              Embark on a culinary journey at our best hotel in Nashik, where
              dining transcends mere sustenance to become a delightful
              experience for the senses. Our passion for gastronomy is evident
              in every dish we present. Savor the rich tapestry of flavors
              meticulously crafted by our skilled chefs, who draw inspiration
              from local ingredients and global culinary traditions.
            </p>
            <p className="d-none">
            Our dining
              venues are carefully curated to offer diverse settings for every
              mood and occasion. Whether you're seeking an intimate dinner for
              two, a lively gathering with friends, or a casual meal by the
              water's edge, our best hotel in Nashik provides the perfect
              backdrop. Indulge in the freshest seasonal ingredients, expertly
              combined to create a menu that tantalizes taste buds and caters to
              diverse palates. From delectable appetizers to sumptuous main
              courses and decadent desserts, each dish is a celebration of
              taste, texture, and creativity.
              Immerse yourself in the inviting ambiance of our restaurants,
              where attentive service complements the culinary excellence.
              Whether you're a discerning food enthusiast or a casual diner, our
              commitment to delivering a memorable dining experience ensures
              that every meal becomes a highlight of your stay. Welcome to a
              world where gastronomy meets luxury, inviting you to relish every
              moment at our dining establishments.
            </p>
          </div>
        </div>
        <div className="row rest-pad" bis_skin_checked={1}>
          <div className="col-lg-5 col-md-6 col-xs-12" bis_skin_checked={1}>
            <div className="rest-content" bis_skin_checked={1}>
              <h2>Lake Resort amidst 37 acres of lush greenery</h2>
              <p>
                Nestled in 37 acres of lush greenery, our best hotel in Nashik
                is a tranquil escape where nature meets luxury. Surrounded by
                pristine landscapes, our accommodations offer breathtaking views
                of the serene lake. Immerse yourself in the beauty of our
                sprawling grounds, featuring manicured gardens and winding
                pathways. Experience a harmonious blend of modern comfort and
                natural splendor in every corner. Whether you're seeking a
                romantic getaway or a family retreat, our best hotel in Nashik,
                provides a haven of relaxation. Indulge in premium amenities,
                personalized service, and the serenity of our surroundings – a
                perfect retreat for those who appreciate the tranquility of
                lakeside living.
              </p>
              {/* <ul>
                <li>Sumptuous Indian and continental meals</li>
                <li>Veg and non-veg; many fresh vegetables are garden</li>
                <li>Bed tea, breakfast, lunch, high tea, soup, dinner, </li>
                <li>Well-trained staff to attend to your needs</li>
                <li>Can accommodate special dietary requests</li>
              </ul> */}
            </div>
          </div>
          <div className="col-lg-7 col-md-6 col-xs-12" bis_skin_checked={1}>
            <img src={cocoDining} alt="" className="img-responsive w-100" />
          </div>
        </div>
      </section>
      <Menu />
    </>
  );
};

export default Dinning;
