import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';
import mukaneDam from "../Asserts/attractionPoint/at(3).webp";
import hillview from "../Asserts/attractionPoint/at(1).webp";
import sunSet from "../Asserts/attractionPoint/at(2).webp";
import trimbekeshwar from "../Asserts/attractionPoint/at(4).webp";
import treking from "../Asserts/attractionPoint/Trekking_Main.webp";

export default function SliderAttractionPoint() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
      
        <SwiperSlide>
        <div
                className=""
                bis_skin_checked={1}
              >
                <div className="item" bis_skin_checked={1}>
                  <img
                    src={sunSet}
                    alt="Best Resort in Nashik"
                    //   style={{maxWidth:"300px", maxHeight:"300px", height:"100%", width:"100%"}}
                    className="img-responsive attraction-img"
                  />
                  <h2>Sunset Point  </h2>
                  <p className='atrra' id='style-4'>
                  The attraction point at sunset is a mesmerizing spectacle, casting vibrant hues across the sky. The sun's descent creates a captivating panorama, painting the landscape with warm tones. Visitors gather to witness this natural beauty, making it a cherished and serene experience that lingers in the memory, evoking a sense of tranquility and awe.
</p>
                </div>
              </div>
        </SwiperSlide>
        <SwiperSlide>
        <div
                className=""
                bis_skin_checked={1}
              >
                <div className="item" bis_skin_checked={1}>
                  <img
                    src={trimbekeshwar}
                    alt="Best Resort "
                    //   style={{maxWidth:"300px", maxHeight:"300px", height:"100%", width:"100%"}}
                    className="img-responsive attraction-img"
                  />
                  <h2>Trimbekeshwar </h2>
                  <p className='' id='style-4'>
                  Trimbakeshwar Temple in Nashik stands as a revered attraction point, drawing pilgrims and tourists alike. Nestled in the lush surroundings, the temple boasts ancient architecture and spiritual significance. Its tranquil ambiance and sacred ponds add to the allure, providing visitors with a deeply enriching and cultural experience in the heart of Nashik.

                  </p>
                </div>
              </div>
        </SwiperSlide>
        <SwiperSlide>
        <div
                className=""
                bis_skin_checked={1}
              >
                <div className="item" bis_skin_checked={1}>
                  <img
                    src={treking}
                    alt="Best Resort"
                    //   style={{maxWidth:"300px", maxHeight:"300px", height:"100%", width:"100%"}}
                    className="img-responsive attraction-img"
                  />
                  <h2>Trekking</h2>
                  <p className='' id='style-4'>
                  Embark on a thrilling journey with our resort's trekking experience. Surrounded by breathtaking landscapes, our guided treks cater to all adventure levels. From novice explorers to seasoned hikers, discover nature's wonders while enjoying the comfort and hospitality of our resort – where every step is a moment of exhilaration and connection with the great outdoors.
                  </p>
                </div>
              </div>
        </SwiperSlide>
        <SwiperSlide> <div
                className=""
                bis_skin_checked={1}
              >
                <div className="item" bis_skin_checked={1}>
                  <img
                    src={hillview}
                    //   style={{maxWidth:"300px", maxHeight:"300px", height:"100%", width:"100%"}}
                    alt="Best Resort "
                    className="img-responsive attraction-img"
                  />
                  <h2>Hill View</h2>
                  <p className='' id='style-4'>
                  Elevate your retreat with breathtaking hill views at our best resort in Nashik. Nestled amidst nature's grandeur, our haven offers panoramic vistas that redefine serenity. Immerse yourself in tranquility as the hills embrace you, creating an unforgettable backdrop for your stay. Experience the beauty of elevated living, where every moment is framed by the majesty of the surrounding hills.
                  </p>
                </div>
              </div></SwiperSlide>
              <SwiperSlide>
        <div
                className=""
                bis_skin_checked={1}
              >
                <div className="item" bis_skin_checked={1}>
                  <img
                    src={mukaneDam}
                    alt="Best  in Nashik"
                    //   style={{maxWidth:"300px", maxHeight:"300px", height:"100%", width:"100%"}}
                    className="img-responsive attraction-img"
                  />
                  <h2>Mukane dam </h2>
                  <p className='' id='style-4'>
                  Mukane Dam, is an earthfill dam on Aaundha river near Igatpuri, Nashik district in the state of Maharashtra in India. Dam in Igatpuri.                  </p>
                </div>
              </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
