import React, { useRef, useState } from 'react';
import $ from "jquery";

import MetaTags from 'react-meta-tags';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import PropertyData from "../../Asserts/PropertyData.jsx";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Image } from 'antd';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';
import { TbSwimming } from "react-icons/tb";
import { IoIosRestaurant } from "react-icons/io";
import { GiHighGrass } from "react-icons/gi";
import { MdSportsCricket } from "react-icons/md";
import { SiFireship } from "react-icons/si";
import { BiCameraMovie } from "react-icons/bi";
import { IoGameController } from "react-icons/io5";
import { IoMdFootball } from "react-icons/io";
import Testimonials from "../../Pages/Testimonials";
import Gallery from "../../Pages/Gallery";
import BannerSlider from "../../Pages/BannerSlider";


import SliderAttractionPoint from '../../Pages/SliderAttractionPoint.jsx';
import { Link } from 'react-router-dom';
const Home = () => {
  const formRef = useRef(null)
  const [custName,serCustName] =useState("")
  const [custEmail,setCustEmail] = useState("")
  const [cusContact,setCustContact] = useState("")
  const [custSubject,setCustSubject] = useState("")
  const [custMessage, setCustMessage] = useState("")
  const [formError, setFormError] = useState(false)

  const ContactEnquiry =(e)=>{
    e.preventDefault();
    if(!custName || !custEmail || !custSubject ||  !custMessage || !cusContact ){
      setFormError(true)
      return;
    }
        // Reset form error state
        setFormError(false);

        // Your form submission logic goes here
        var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">Coconut County</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${cusContact}</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left">${custSubject}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Coconut County</p></div></body></html>`;
    
        $.post(
          "https://skdm.in/server/v1/send_lead_mail.php",
          {
            toEmail: "suryawanshiresort@gmail.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Customer Lead",
            mailBody: body,
            accountName: "coconutcounty",
            accountLeadSource: "https://skdm.in",
            accountLeadName: custName,
          },
          function (dataa, status) {
            console.log("data :" + dataa);
          }
        );
    
        // Reset the form
        if (formRef.current) {
          formRef.current.reset();
        }
    
        alert("Your Form has Submitted. Our team will contact you soon.");
    
  }
  return (
    <>
     <MetaTags>
      <title>Best Resort in Nashik | Coconut County Resort</title>
        <meta title="Best Resort in Nashik | Coconut County Resort" />
        <meta
          name="description"
          content="Discover unparalleled luxury and tranquility at Coconut County Resort, voted the best in Nashik, for an unforgettable escape."
        />
        <meta
          name="keywords"
          content="Best Resort in Nashik, Coconut County in Nashik,Nature Resort near Nashik,Best Hotel In Nashik,Hotel Near Nashik With Pool,Resort Near Nashik,Top Resort in Nashik,Best Villa in Nashik,Resort with Deluxe Rooms in Nashik, Top Resort with Deluxe Rooms in Nashik ,Best Resort with Deluxe Rooms in Nashik,Resort with Meeting Hall in Nashik,Best Resort with Party Hall in Nashik"

        />
        <link rel="canonical" href="https://coconutcounty.in/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Resort in Nashik | Coconut County Resort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://coconutcounty.in/"/>
        <meta property="og:description" content="Discover unparalleled luxury and tranquility at Coconut County Resort, voted the best in Nashik, for an unforgettable escape." />
        <meta property="og:image" content="https://coconutcounty.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
      <BannerSlider />
    
      {/* Search style*/}
      {/* About us Start */}
      <section>
        <div className="resot-activities clearfix custom-padding" bis_skin_checked={1}>
          <div className="container clearfix common-pad" bis_skin_checked={1}>
            <div className="row" bis_skin_checked={1}>
              <div
                className="col-lg-6 col-md-12 activities-cont"
                bis_skin_checked={1}
              >
                <div className="sec-header3" bis_skin_checked={1}>
                  <h2>About Our Resort</h2>
                  <h3>Our resort offers a luxurious escape with amenities and tranquility.</h3>
                </div>
                <p>
                Welcome to Coconut county, the best resort in Nashik, where luxury meets nature in a perfect blend of serenity and excitement. Nestled amidst lush landscapes, our haven boasts a refreshing swimming pool that invites you to unwind under the warm sun or indulge in a refreshing swim. As the day transitions into night, gather around our mesmerizing bonfire – a hub of camaraderie and shared stories, creating unforgettable moments.
                </p>
                <p>
                Immerse yourself in the expansive open lawn, providing an idyllic setting for leisurely strolls or engaging outdoor activities. Whether you seek tranquility by the poolside or the thrill of a bonfire evening, our best resort in Nashik, caters to every desire. Our commitment to impeccable service ensures that your stay is seamless and memorable.                
                </p>
                <p className='d-none'>
                Indulge your senses in the natural beauty that surrounds us, from the vibrant greenery to the starlit skies above. Escape the ordinary, embrace the extraordinary – at our best resort in Nashik, your getaway becomes an experience of unparalleled bliss. Discover the perfect fusion of luxury and leisure as you create timeless memories in our serene oasis.
                </p>
                <Link to="/coconut-county-nashik">
                <a href="/coconut-county-nashik" className="res-btn" bis_skin_checked={1}>
                  About us
                  <i className="fa fa-arrow-right" />
                </a>
                </Link>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12 col-xs-12"
                bis_skin_checked={1}
              >
          
                  <div className="" bis_skin_checked={1}>
                  <div style={{ padding: "75.25% 0 0 0", position: "relative" }}>
  <iframe
    src="https://player.vimeo.com/video/921051178?badge=0&autopause=0&player_id=0&app_id=58479"
    // frameBorder={0}
    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    }}
    // title="DJI_0799"
  />
</div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About us end */}
      {/* Activities of Resort style*/}
      <div className="resot-activities clearfix custom-padding">
        <div className="container clearfix common-pad">
          <div className="row">
            <div className="col-lg-12 col-md-12 activities-cont">
              <div className="sec-header3">
                <h2>Activities of Resort</h2>
                <h3>The resort offers diverse activities, ensuring a memorable and enjoyable stay.</h3>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="row nasir-welboxes">
                <div className="single_wel_cont col-sm-6">
                  <a href="#" className="wel-box">
                    <div className="icon-box">
                      <TbSwimming />
                      {/* <img src="images/welcome/icon-3.png" alt="" /> */}
                    </div>
                    <h4>swimming pool</h4>
                    {/* <div className="overlay transition3s">
                  <div className="icon_position_table">
                    <div className="icon_container border_round">
                      <h2>swimming pool</h2>
                      <p>
                        Neque porro quisquam est, qui dolorem ipsum quia orro
                        quisquam estdqAolor{" "}
                      </p>
                    </div>
                  </div>
                </div> */}
                  </a>
                </div>
                <div className="single_wel_cont col-sm-6">
                  <a href="#" className="wel-box">
                    <div className="icon-box">
                      <IoIosRestaurant />

                      {/* <img src="images/welcome/icon-1.png" alt="" /> */}
                    </div>
                    <h4>Inhouse Restaurant</h4>
                    {/* <div className="overlay transition3s">
                  <div className="icon_position_table">
                    <div className="icon_container border_round">
                      <h2>Inhouse Restaurant</h2>
                      <p>
                        Neque porro quisquam est, qui dolorem ipsum quia orro
                        quisquam estdqAolor{" "}
                      </p>
                    </div>
                  </div>
                </div> */}
                  </a>
                </div>
                <div className="single_wel_cont col-sm-6">
                  <a href="#" className="wel-box">
                    <div className="icon-box">
                      <SiFireship />
                      {/* <img src="images/welcome/icon-2.png" alt="" /> */}
                    </div>
                    <h4>Bonfire</h4>
                    {/* <div className="overlay transition3s">
                  <div className="icon_position_table">
                    <div className="icon_container border_round">
                      <h2>Bonfire</h2>
                      <p>
                        Neque porro quisquam est, qui dolorem ipsum quia orro
                        quisquam estdqAolor{" "}
                      </p>
                    </div>
                  </div>
                </div> */}
                  </a>
                </div>
                <div className="single_wel_cont col-sm-6">
                  <a href="#" className="wel-box">
                    <div className="icon-box">
                      <BiCameraMovie />

                      {/* <img src="images/welcome/icon-3.png" alt="" /> */}
                    </div>
                    <h4>Entertainment</h4>
                    {/* <div className="overlay transition3s">
                  <div className="icon_position_table">
                    <div className="icon_container border_round">
                      <h2>Entertainment</h2>
                      <p>
                        Neque porro quisquam est, qui dolorem ipsum quia orro
                        quisquam estdqAolor{" "}
                      </p>
                    </div>
                  </div>
                </div> */}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="row nasir-welboxes">
                <div className="single_wel_cont col-sm-6">
                  <a href="#" className="wel-box">
                    <div className="icon-box">
                      <GiHighGrass />
                      {/* <img src="images/welcome/icon-3.png" alt="" /> */}
                    </div>
                    <h4>Open Lawn Area</h4>
                    {/* <div className="overlay transition3s">
                  <div className="icon_position_table">
                    <div className="icon_container border_round">
                      <h2>Open Lawn Area</h2>
                      <p>
                        Neque porro quisquam est, qui dolorem ipsum quia orro
                        quisquam estdqAolor{" "}
                      </p>
                    </div>
                  </div>
                </div> */}
                  </a>
                </div>
                <div className="single_wel_cont col-sm-6">
                  <a href="#" className="wel-box">
                    <div className="icon-box">
                      <MdSportsCricket />

                      {/* <img src="images/welcome/icon-1.png" alt="" /> */}
                    </div>
                    <h4>Outdoor Games</h4>
                    {/* <div className="overlay transition3s">
                  <div className="icon_position_table">
                    <div className="icon_container border_round">
                      <h2>Outdoor Games</h2>
                      <p>
                        Neque porro quisquam est, qui dolorem ipsum quia orro
                        quisquam estdqAolor{" "}
                      </p>
                    </div>
                  </div>
                </div> */}
                  </a>
                </div>
                <div className="single_wel_cont col-sm-6">
                  <a href="#" className="wel-box">
                    <div className="icon-box">
                      <IoGameController />

                      {/* <img src="images/welcome/icon-2.png" alt="" /> */}
                    </div>
                    <h4>Indoor Games</h4>
                    {/* <div className="overlay transition3s">
                  <div className="icon_position_table">
                    <div className="icon_container border_round">
                      <h2>Indoor Games</h2>
                      <p>
                        Neque porro quisquam est, qui dolorem ipsum quia orro
                        quisquam estdqAolor{" "}
                      </p>
                    </div>
                  </div>
                </div> */}
                  </a>
                </div>
                <div className="single_wel_cont col-sm-6">
                  <a href="#" className="wel-box">
                    <div className="icon-box">
                      <IoMdFootball />

                      {/* <img src="images/welcome/icon-3.png" alt="" /> */}
                    </div>
                    <h4>Turf</h4>
                    {/* <div className="overlay transition3s">
                  <div className="icon_position_table">
                    <div className="icon_container border_round">
                      <h2>Turf</h2>
                      <p>
                        Neque porro quisquam est, qui dolorem ipsum quia orro
                        quisquam estdqAolor{" "}
                      </p>
                    </div>
                  </div>
                </div> */}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Rooms And Suits style*/}
      <section className="container clearfix common-pad nasir-style custom-padding">
        <div className="sec-header sec-header-pad">
          <h2>Properties </h2>
          <h3>The resort property features stunning landscapes, comfort, and recreational facilities."</h3>
          <p className='d-none'>Experience unparalleled luxury and comfort in our resort rooms, where every stay is a harmonious blend of relaxation and sophistication.
          Step into a world of comfort and convenience in our resort rooms. Immerse yourself in the plush surroundings, where modern amenities meet timeless elegance. Each room is thoughtfully designed to ensure a seamless stay, featuring luxurious bedding, high-speed Wi-Fi, and breathtaking views. From the rejuvenating spa-like bathrooms to in-room entertainment, every detail is crafted to enhance your experience. Indulge in a retreat that seamlessly combines relaxation with modern luxury.
          </p>
        </div>
        <div className="row">
        <Swiper
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
       {PropertyData.map((v, i) => {
            return (
              <>
              <SwiperSlide>
                <div className="mx-4">
                  <div
                    className="owl-item active"
                    style={{  }}
                    bis_skin_checked={1}
                  >
                    <div
                      className="room-suite room-suite-htwo"
                      bis_skin_checked={1}
                    >
                      <div className="item" bis_skin_checked={1}>
                        <div className="ro-img" bis_skin_checked={1}>
                          <img
                            style={{
                              maxWidth: "100%",
                              maxHeight: "200px",
                              height: "100%",
                              width: "100%",
                              objectFit:"cover"
                            }}
                            src={v.propertyImage[0]}
                            alt="Best Resort in Nashik"
                            className="img-responsive"
                          />
                        </div>
                        <div className="ro-txt room-desc" bis_skin_checked={1}>
                          <h2> {v.propertyName}</h2>
                          <p>{v.sub_title}</p>
                          
                          <div
                            className="ro-text-two d-flex gap-5"
                            bis_skin_checked={1}
                          >
                          <Link to={`/top-resort-nashik${v.slugs}`}>
                            <div
                              className="left-p-two pull-left"
                              bis_skin_checked={1}
                            >
                             
                              <a
                                href="#"
                                className="res-btn"
                                bis_skin_checked={1}
                              >
                                details
                              </a>
                             
                            </div>
                            {/* <div className="right-p-two pull-right" bis_skin_checked={1}>
            <p>
              $215<span>Per Night</span>
            </p>
          </div> */}
          </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </SwiperSlide>
              </>
            );
          })}
      </Swiper>
         
        </div>

      </section>
      {/* attraction point */}
      <section className="our-special-wrapper custom-padding">
        <section className="container clearfix common-pad">
          <div className="sec-header3" bis_skin_checked={1}>
            <h2>Attraction Points </h2>
            <h3>Explore captivating wonders just steps away from our best resort in Nashik.</h3>
          </div>
          <p className='d-none'>
          Our prime location grants you easy access to a mesmerizing attraction point, where natural beauty and cultural richness converge. Immerse yourself in an unforgettable experience, making your stay with us a gateway to both relaxation and adventure.
          </p>
          <div className="row" bis_skin_checked={1}>
            <div className="row our-spec-outer" bis_skin_checked={1}>
              <SliderAttractionPoint />
            </div>
          </div>
        </section>
      </section>
      {/* Counter style*/}
      <section className="resort-counert clearfix">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="rest-fact-counter">
                <div className="text-box">
                  <h4 data-from={0} data-to={121} className="timer">
                    121
                  </h4>
                </div>
                <div className="text-box2">
                  <p>
                    new<span>friendships</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="rest-fact-counter">
                <div className="text-box">
                  <h4 data-from={0} data-to={254} className="timer">
                    254
                  </h4>
                </div>
                <div className="text-box2">
                  <p>
                    five star<span>ratings</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="rest-fact-counter">
                <div className="text-box">
                  <h4 data-from={0} data-to={430} className="timer">
                    430
                  </h4>
                </div>
                <div className="text-box2">
                  <p>
                    <span>Guests</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="rest-fact-counter">
                <div className="text-box">
                  <h4 data-from={0} data-to={782} className="timer">
                    782
                  </h4>
                </div>
                <div className="text-box2">
                  <p>
                    Served<span>Breakfast</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Our Offer style*/}
      {/* <section className="our-offer-htwo clearfix">
    <div className="container clearfix common-pad">
      <div className="row">
        <div className="col-md-4 our-offer-left">
          <div className="sec-header3">
            <h2>Our Offers</h2>
            <h3>Pick a room that best suits</h3>
          </div>
          <p>
            Tdolor sit amet, consectetur, adipis civelit sed quia non qui
            dolorem ipsum quia dolor sit amet, consectetur, adipis civelit. Red
            quia numquam.
          </p>
          <p>
            Tdolor sit amet, consectetur, adipis civelit sed quia non qui
            dolorem ipsum quia dolor sit amet, consectetur, adipis civelit. Red
            quia numquam eius modi. Neque porro quisquam.
          </p>
        </div>
        <div className="col-md-8 offer-right">
          <div className="offer-img-box1">
            <div className="spa-offer">
              <div className="img_holder">
                <img
                  src="images/our-resort/1.jpg"
                  alt=""
                  className="img-responsive"
                />
                <div className="overlay">
                  <div className="room-ad-cont">
                    <h2>
                      25% <span>off</span>
                    </h2>
                    <h3>Weeken Spa Offer</h3>
                    <p>
                      Enjoy a luxurious SPA weekend dedicated to helping you
                      unwind.
                    </p>
                    <a href="booking.html">Read more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="offer-img-box2">
            <div className="box1">
              <div className="img_holder">
                <a href="booking.html">
                  <img
                    src="images/our-resort/2.jpg"
                    alt=""
                    className="img-responsive"
                  />
                  <div className="overlay">
                    <div className="text1">* condition apply</div>
                    <div className="offertext1">
                      <p>
                        15% <span className="off-txt">off</span>
                        <span className="winter-txt">
                          In Winter
                          <br />
                          Season
                        </span>
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="box2">
              <div className="img_holder">
                <a href="booking.html">
                  <img
                    src="images/our-resort/3.jpg"
                    alt=""
                    className="img-responsive"
                  />
                  <div className="overlay">
                    <p>
                      Honeymoon <span>Offer</span>
                    </p>
                    <h2>
                      25% <span>off</span>
                    </h2>
                    <h6>* condition apply</h6>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
      {/* Our Gallery style*/}
      <section className="our-galler-htwo clearfix common-pad">
        <div className="container clearfix">
          <div className="sec-header sec-w-header">
            <h2 className="">Our Gallery</h2>
            <h3>Pick a room that best suits your taste and budget</h3>
          </div>
        </div>
        <Gallery />
       
      </section>
      {/* Testimonials & Our Events style*/}
      <div className="container clearfix common-pad custom-padding mt-5">
        <div className="sec-header-two">
          <h2>Testimonials</h2>
        </div>
        <div className="row">
          <Testimonials />
          {/* <div className="col-lg-12 col-md-12">
        
        <div className="testimonials-wrapper">
          <div className="testimonial-sliders-two owl-carousel owl-theme owl-responsive--1 owl-loaded">
            <div className="owl-stage-outer">
              <div
                className="owl-stage"
                style={{
                  transform: "translate3d(-2400px, 0px, 0px)",
                  transition: "all 0.25s ease 0s",
                  width: 5400
                }}
              >
                <div
                  className="owl-item cloned"
                  style={{ width: 570, marginRight: 30 }}
                >
                  <div className="item">
                    <div className="test-cont">
                      <p>
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit, sed quia consequuntur magni dolores
                        eos qui ratione voluptatem sequi nesciunt. Neque porro
                        quisquam est, qui dolorem ipsum quia dolor sit amet,
                        consectetur.
                      </p>
                    </div>
                    <div className="test-bot">
                      <div className="tst-img">
                        <img
                          src="images/testimonials/4.png"
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      <div className="client_name">
                        <h5>
                          <a href="testimonials.html">
                            Mark John - <span>Nescom Technology- Director</span>
                          </a>
                        </h5>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: 570, marginRight: 30 }}
                >
                  <div className="item">
                    <div className="test-cont">
                      <p>
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit, sed quia consequuntur magni dolores
                        eos qui ratione voluptatem sequi nesciunt. Neque porro
                        quisquam est, qui dolorem ipsum quia dolor sit amet,
                        consectetur.
                      </p>
                    </div>
                    <div className="test-bot">
                      <div className="tst-img">
                        <img
                          src="images/testimonials/5.png"
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      <div className="client_name">
                        <h5>
                          <a href="testimonials.html">
                            Mark John - <span>Nescom Technology- Director</span>
                          </a>
                        </h5>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item"
                  style={{ width: 570, marginRight: 30 }}
                >
                  <div className="item">
                    <div className="test-cont">
                      <p>
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit, sed quia consequuntur magni dolores
                        eos qui ratione voluptatem sequi nesciunt. Neque porro
                        quisquam est, qui dolorem ipsum quia dolor sit amet,
                        consectetur.
                      </p>
                    </div>
                    <div className="test-bot">
                      <div className="tst-img">
                        <img
                          src="images/testimonials/1.png"
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      <div className="client_name">
                        <h5>
                          <a href="testimonials.html">
                            Mark John - <span>Nescom Technology- Director</span>
                          </a>
                        </h5>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item"
                  style={{ width: 570, marginRight: 30 }}
                >
                  <div className="item">
                    <div className="test-cont">
                      <p>
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit, sed quia consequuntur magni dolores
                        eos qui ratione voluptatem sequi nesciunt. Neque porro
                        quisquam est, qui dolorem ipsum quia dolor sit amet,
                        consectetur.
                      </p>
                    </div>
                    <div className="test-bot">
                      <div className="tst-img">
                        <img
                          src="images/testimonials/2.png"
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      <div className="client_name">
                        <h5>
                          <a href="testimonials.html">
                            Mark John - <span>Nescom Technology- Director</span>
                          </a>
                        </h5>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item active"
                  style={{ width: 570, marginRight: 30 }}
                >
                  <div className="item">
                    <div className="test-cont">
                      <p>
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit, sed quia consequuntur magni dolores
                        eos qui ratione voluptatem sequi nesciunt. Neque porro
                        quisquam est, qui dolorem ipsum quia dolor sit amet,
                        consectetur.
                      </p>
                    </div>
                    <div className="test-bot">
                      <div className="tst-img">
                        <img
                          src="images/testimonials/3.png"
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      <div className="client_name">
                        <h5>
                          <a href="testimonials.html">
                            Mark John - <span>Nescom Technology- Director</span>
                          </a>
                        </h5>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item"
                  style={{ width: 570, marginRight: 30 }}
                >
                  <div className="item">
                    <div className="test-cont">
                      <p>
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit, sed quia consequuntur magni dolores
                        eos qui ratione voluptatem sequi nesciunt. Neque porro
                        quisquam est, qui dolorem ipsum quia dolor sit amet,
                        consectetur.
                      </p>
                    </div>
                    <div className="test-bot">
                      <div className="tst-img">
                        <img
                          src="images/testimonials/4.png"
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      <div className="client_name">
                        <h5>
                          <a href="testimonials.html">
                            Mark John - <span>Nescom Technology- Director</span>
                          </a>
                        </h5>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item"
                  style={{ width: 570, marginRight: 30 }}
                >
                  <div className="item">
                    <div className="test-cont">
                      <p>
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit, sed quia consequuntur magni dolores
                        eos qui ratione voluptatem sequi nesciunt. Neque porro
                        quisquam est, qui dolorem ipsum quia dolor sit amet,
                        consectetur.
                      </p>
                    </div>
                    <div className="test-bot">
                      <div className="tst-img">
                        <img
                          src="images/testimonials/5.png"
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      <div className="client_name">
                        <h5>
                          <a href="testimonials.html">
                            Mark John - <span>Nescom Technology- Director</span>
                          </a>
                        </h5>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: 570, marginRight: 30 }}
                >
                  <div className="item">
                    <div className="test-cont">
                      <p>
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit, sed quia consequuntur magni dolores
                        eos qui ratione voluptatem sequi nesciunt. Neque porro
                        quisquam est, qui dolorem ipsum quia dolor sit amet,
                        consectetur.
                      </p>
                    </div>
                    <div className="test-bot">
                      <div className="tst-img">
                        <img
                          src="images/testimonials/1.png"
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      <div className="client_name">
                        <h5>
                          <a href="testimonials.html">
                            Mark John - <span>Nescom Technology- Director</span>
                          </a>
                        </h5>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: 570, marginRight: 30 }}
                >
                  <div className="item">
                    <div className="test-cont">
                      <p>
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit, sed quia consequuntur magni dolores
                        eos qui ratione voluptatem sequi nesciunt. Neque porro
                        quisquam est, qui dolorem ipsum quia dolor sit amet,
                        consectetur.
                      </p>
                    </div>
                    <div className="test-bot">
                      <div className="tst-img">
                        <img
                          src="images/testimonials/2.png"
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      <div className="client_name">
                        <h5>
                          <a href="testimonials.html">
                            Mark John - <span>Nescom Technology- Director</span>
                          </a>
                        </h5>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-controls">
              <div className="owl-nav">
                <div className="owl-prev" style={{ display: "none" }}>
                  prev
                </div>
                <div className="owl-next" style={{ display: "none" }}>
                  next
                </div>
              </div>
              <div className="owl-dots" style={{}}>
                <div className="owl-dot">
                  <span />
                </div>
                <div className="owl-dot">
                  <span />
                </div>
                <div className="owl-dot active">
                  <span />
                </div>
                <div className="owl-dot">
                  <span />
                </div>
                <div className="owl-dot">
                  <span />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
          {/* <div className="col-lg-6 col-md-6 event-wrapper">
        <div className="sec-header-two">
          <h2>Our Events</h2>
        </div>
        <div className="our-event-t-wrapper">
          <div className="media">
            <div className="media-left">
              <div className="date-box">
                <div className="date-inner">
                  <div className="date-c-inner">
                    <p>
                      25<span>June</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="media-body">
              <h2>Host a Family Party</h2>
              <p>
                Nemo enim ipsam voluptatem quia voluptas sit aspern atur aut
                odit aut fugit, sed quia consequuntur magni dolores eos qui
                ratione voluptatem.
              </p>
            </div>
          </div>
          <div className="media">
            <div className="media-left">
              <div className="date-box">
                <div className="date-inner">
                  <div className="date-c-inner">
                    <p>
                      08<span>June</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="media-body">
              <h2>Host a Family Party</h2>
              <p>
                Nemo enim ipsam voluptatem quia voluptas sit aspern atur aut
                odit aut fugit, sed quia consequuntur magni dolores eos qui
                ratione voluptatem.
              </p>
            </div>
          </div>
          <div className="media media-last">
            <div className="media-left">
              <div className="date-box">
                <div className="date-inner">
                  <div className="date-c-inner">
                    <p>
                      08<span>June</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="media-body">
              <h2>Host a Family Party</h2>
              <p>
                Nemo enim ipsam voluptatem quia voluptas sit aspern atur aut
                odit aut fugit, sed quia consequuntur magni dolores eos qui
                ratione voluptatem.
              </p>
            </div>
          </div>
        </div>
      </div> */}
        </div>
      </div>
      {/* Get in Touch & Drop a Message style*/}
      <div className="resot-activities clearfix custom-padding">
        <div className="container clearfix common-pad">
          <div className="row">
            <div className="col-lg-6 col-md-7 get-touch-two">
              <div className="get-touch-wrapper row m0">
                <div className="touch-img">
                  <img
                    src="images/footer/1.jpg"
                    alt=""
                    className="img-responsive"
                  />
                </div>
                <div className="touch-txt">
                  <div className="sec-header-touch">
                    <h2>Get in Touch</h2>
                  </div>
                  <h3>
                    Pawar <span>(Senior Agent)</span>
                  </h3>
                  <p>Phone : 8668207978/ 8421974306</p>
                  <p>Email : suryawanshiresort@gmail.com</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 home-two-msgwrapper">
              <div className="sec-header-touch">
                <h2>Drop a Message</h2>
              </div>
              <div className="drop-wrapper input_form">
                <form
                  id="contactForm"
          ref={formRef}
          action=""
          method="post"
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <input
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Your name"
                        className="form-control"
                        onChange={(e)=> serCustName(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <input
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        onChange={(e)=> setCustEmail(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="row" bis_skin_checked={1}>
          <div
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
              bis_skin_checked={1}
            >
              {/* <span>Phone Number</span> */}
              <input
                id="PhoneNumber"
                type="tel"
                name="PhoneNumber"
                placeholder="Phone Number"
                className="form-control"
                onChange={(e)=> setCustContact(e.target.value)}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
              bis_skin_checked={1}
            >
              {/* <span>Subject</span> */}
              <input
                id="subject"
                type="text"
                name="subject"
                placeholder="Subject"
                className="form-control"
                onChange={(e)=> setCustSubject(e.target.value)}
              />
            </div>
          </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <textarea
                        id="message"
                        rows={6}
                        name="message"
                        placeholder="Message"
                        onChange={(e)=> setCustMessage(e.target.value)}
                        className="form-control"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  {formError && (
            <p style={{ color:"red"}}>Please fill in all required fields</p>
          )}
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <button type="submit" onClick={ContactEnquiry} className="res-btn">
                        Submit Now <i className="fa fa-arrow-right" />
                      </button>
                    </div>
                  </div>
                </form>
                <div id="success">
                  <p>Your message sent successfully.</p>
                </div>
                <div id="error">
                  <p>Something is wrong. Message cant be sent!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
