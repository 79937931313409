import React from "react";
import {Link} from "react-router-dom"
const Footer = () => {
  return (
    <>
      <footer>
        <section className="clearfix custom-padding footer-wrapper">
          <section className="container clearfix footer-pad ">
            <div className="row">
              <div className="widget about-us-widget col-md-4 col-sm-6">
                {/* <a href="index.html">
                  <img
                    src="images/footer/logo.png"
                    alt=""
                    className="img-responsive"
                  />
                </a> */}
                <p>
                  Welcome to Coconut county, the best resort in Nashik, where
                  luxury meets nature in a perfect blend of serenity and
                  excitement. 
                </p>
                {/* <a href="aboutus.html">
                  Read More <i className="fa fa-angle-double-right" />
                </a> */}
                <ul className="nav">
                  {/* <li>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li> */}
                  {/* <li>
                    <a href="#">
                      <i className="fa fa-linkedin-square" />
                    </a>
                  </li> */}
                  <li>
                    <a href="https://wa.me/8668207978" target="_blank">
                      <i className="fa fa-whatsapp" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/coconutcountyfarm/"
                      target="_blank"
                    >
                      <i className="fa fa-facebook-square" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/coconutcountyfarm/"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="widget widget-links col-md-2 col-sm-6">
                <h4 className="widget_title">Quick Links</h4>
                <div className="widget-contact-list">
                  <ul>
                    <li>
                      <Link to="/"><a href="/">Home</a></Link>
                    </li>
                    <li>
                    <Link to="/coconut-county-nashik"> <a href="/coconut-county-nashik">About Us</a></Link>
                      
                    </li>
                    <li>
                      <Link to="/top-resort-nashik">
                      <a href="/top-resort-nashik"> Property</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="widget get-in-touch col-md-4 col-sm-6">
                <h4 className="widget_title">Get In Touch</h4>
                <div className="widget-contact-list">
                  <ul>
                    <li>
                      <i className="fa fa-map-marker" />
                      <div className="fleft location_address">
                        <b> Resort</b>
                        <br />
                        Murambi, Wadiwarhe-Ahurli,Road, Trimbak, road, Nashik,
                        Maharashtra 422403{" "}
                      </div>
                    </li>
                    <li>
                      <i className="fa fa-phone" />
                      <div className="fleft contact_no">
                        <a href="tel:+918421974306">8421974306</a> /{" "}
                        <a href="tel:+918668207978">8668207978</a>
                      </div>
                    </li>
                    <li>
                      <i className="fa fa-envelope-o" />
                      <div className="fleft contact_mail">
                        <a href="mailto:suryawanshiresort@gmail.com">
                          suryawanshiresort@gmail.com
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="widget get-in-touch col-md-2 col-sm-6">
                <h4 className="widget_title">Location</h4>
                <div className="widget-contact-list">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15011.956714677091!2d73.6387434!3d19.8404108!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdd8f44122c59f9%3A0x8bafbe132a25decc!2sCoconut%20County%20resort!5e0!3m2!1sen!2sin!4v1708081546943!5m2!1sen!2sin"
                    // width={350}
                    // height={250}
                    style={{
                      border: 0,
                      borderRadius: "25px",
                      maxWidth: "250px",
                    }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className="container clearfix footer-b-pad">
          <div className="footer-copy">
            <div className="pull-left fo-txt">
              <p>Copyright © Coconut county 2024. All rights reserved. </p>
            </div>
            <div className="pull-right fo-txt">
              <p>
                Created by:{" "}
                <a href="https://skdm.in/">Shree Krishna digital marketing</a>
              </p>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
