import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '../Css/NavGallery.css'
import { TiEyeOutline } from "react-icons/ti";
import galleryData from "../Asserts/GalleryData";
import BreadCrumb from "../Pages/BreadCrumb";
const NavGallery = () => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = galleryData

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <>
     <MetaTags>
      <title>Hotel Near Nashik With Pool | Coconut County Resort</title>
        <meta title="Hotel Near Nashik With Pool | Coconut County Resort" />
        <meta
          name="description"
          content="Coconut County Resort offers a serene escape, a hotel near Nashik with a refreshing pool, promising relaxation amid picturesque surroundings."
        />
        <meta
          name="keywords"
          content="Party Hall in Kandivali,Best Banquet in Kandivali,Party Hall For Events in Kandivali,Best Party Hall in Kandivali For Weddings,Banquet for Engagement in Kandivali,Birthday Party Hall in Kandivali,Party Hall in Kandivali for Naming Ceremony, Baby Shower Venues & Halls near Kandivali , Mehendi venues in Kandivali,Top Party Hall in Kandivali for Sangeet,Party Hall for Corporate Event in Kandivali,Party Hall with Stay in Kandivali,Best Banquet Hall in Kandivali,Top Banquet near Kandivali,Banquet Hall with Homestay in Kandivali,Banquet Halls with Catering in Kandivali,Banquet Hall For Social Events in Kandivali,Best Banquet near Kandivali"
        />
        <link rel="canonical" href="https://coconutcounty.in/hotel-near-nashik-with-pool" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Hotel Near Nashik With Pool | Coconut County Resort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://coconutcounty.in/hotel-near-nashik-with-pool"/>
        <meta property="og:description" content="Coconut County Resort offers a serene escape, a hotel near Nashik with a refreshing pool, promising relaxation amid picturesque surroundings." />
        <meta property="og:image" content="https://coconutcounty.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
        <BreadCrumb pageName="Hotel Near Nashik With Pool" slugs="Gallery" />
        <p className='d-none'>
        Welcome to our resort's Gallery,a hotel near Nashik with pool, a visual journey capturing the essence of our haven amidst nature's beauty. As you browse through our carefully curated collection, witness the harmonious blend of luxury and natural splendor that defines our resort.

Immerse yourself in the captivating landscapes that surround our 37-acre retreat. From panoramic views of the lush greenery to serene lakeside scenes, each image reflects the tranquility and serenity that await our guests. Explore the meticulously designed accommodations, where modern sophistication seamlessly integrates with the beauty of the outdoors.

Our gallery showcases the diverse dining experiences offered at our hotel near Nashik with pool. From elegantly set tables overlooking the lake to casual al fresco meals amidst manicured gardens, each photo encapsulates the culinary delights and inviting ambiance of our dining venues.

Capturing the essence of our hotel near Nashik with pool experience, the gallery also features moments of relaxation and recreation. Whether it's guests unwinding by the pool, or partaking in outdoor activities, every image reflects the joy and rejuvenation that our resort endeavors to provide.

At the heart of our Gallery lies a commitment to excellence, from the tastefully designed interiors to the attentive service our guests receive. The images convey the passion and dedication with which we create a retreat where every detail is considered for the utmost comfort and satisfaction of our guests.

We invite you to peruse our Gallery and envision yourself immersed in the tranquility, luxury, and natural beauty that define our hotel near Nashik with pool. Each photo tells a story, inviting you to experience the moments that make our resort a unique and memorable destination for those seeking a harmonious blend of indulgence and nature's wonders.

        </p>
    <section className="smile-section">
        <div className="container">
           <div className="row row-cols-md-4 row-cols-sm-2 row-cols-1">
      {images.map((image, index) => (
        <div className="col mb-md-5 mb-5" key={index}>
          <div className="smile-img" onClick={() => openLightbox(index)}>
            <img src={image.img} alt={`coconut county ${index + 1}`} />
            <div className="smile-icon">
              <TiEyeOutline />
            </div>
          </div>
        </div>
      ))}
      {lightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex].img}
          nextSrc={images[(photoIndex + 1) % images.length].img}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].img}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
        </div>
      </section> 
    </>
  )
}

export default NavGallery;
