const TestimonialData = [
    
    {
        id:2,
        review:"This place is awesome.. Please visit with Family and Friends.. The food quality was very good and so do the quantity.. They served us like a family only.. I will visit this place in future for sure..        ",
        img : "https://lh3.googleusercontent.com/a-/ALV-UjWTlf0YwMk4QT9etz0BnsIMbmk2Nv2q0l0JBRlpOHQftZI=s40-c-rp-mo-br100",
        name: "Abhijit Gole"
    },
    {
        id:3,
        review:"Food quality was the best one. It's the heaven on the earth. The staff is very honest and loyal. The hospitality is wonderful.I feel that I was VIP as the treatment received there. Must visited place to enjoy the paradise on the earth        ",
        img : "https://lh3.googleusercontent.com/a/ACg8ocJrFfXez2l1hk9TZn9k1NR9IhtqtX843YATYyic8ffA=s40-c-rp-mo-br100",
        name: "Ganpat Gaikwad"
    },
    {
        id:4,
        review:"Such nice place to visit with your family and friends. Food quality and all the services provided are very good 💯😊. Highly recommended to visit this .        ",
        img : "https://www.w3schools.com/w3css/img_avatar3.png",
        name: "NANDINI ACHUGATLA"
    },
    {
        id:1,
        review:"We spent three days and two nights at Coconut County Resort for New Year celebrations. The recently developed property boasts excellent amenities, including a swimming pool, a cricket turf ground, and various kids’ activities. Indoors, there are gaming zones featuring chess, carrom, and table tennis.The owner of the resort was remarkably cooperative and humble, going above and beyond to ensure our stay was comfortable and relaxed. He even took us to the nearby dam, located just behind the resort, for a breathtaking sunset experience.",
        img : "https://lh3.googleusercontent.com/a-/ALV-UjUAApJE-peaURkiWpmBMOyEWvZ5GmOTel8tEBgOvUrO8Q=s40-c-rp-mo-ba4-br100",
        name: "Durvesh Gawand"
    },

]

export default TestimonialData;