import React from 'react'
import banner2 from "../Asserts/banner3.jpeg";

const BreadCrumb = ({pageName,slugs,property}) => {
  return (
    <>
         <section class="row final-inner-header">
      <div class="container" bis_skin_checked="1" style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(${banner2})`,
          backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        }}>
        <h1 class="this-title text-center">{pageName}</h1>
      </div>
    </section>
        <section className="row final-breadcrumb">
  <div className="container" style={{marginLeft:"auto",paddingLeft:"15px", paddingRight:"15px"}} bis_skin_checked={1}>
    <ol className="breadcrumb ">
      <li>
        <a href="index.html">Home</a>
      </li>
      {property? <li>
        <a href="index.html">{property}</a>
      </li>: <></>}
      <li className="active">{slugs}</li>
    </ol>
  </div>
</section>
    </>
  )
}

export default BreadCrumb