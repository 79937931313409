import React ,{useEffect} from 'react'
import  "../Css/Menu.css";
import manu1 from "../Asserts/menu/Coconut County Resort Menu_page-0001.webp";
import manu2 from "../Asserts/menu/Coconut County Resort Menu_page-0002.webp";
import manu3 from "../Asserts/menu/Coconut County Resort Menu_page-0003.webp";
import banner from "../Asserts/banner.jpg";

const Menu = () => {
    useEffect(() => {
        const pages = document.getElementsByClassName('page');
        for (let i = 0; i < pages.length; i++) {
          const page = pages[i];
          if (i % 2 === 0) {
            page.style.zIndex = pages.length - i;
          }
        }
    
        for (let i = 0; i < pages.length; i++) {
          pages[i].pageNum = i + 1;
          pages[i].addEventListener('click', function () {
            if (this.pageNum % 2 === 0) {
              this.classList.remove('flipped');
              this.previousElementSibling.classList.remove('flipped');
            } else {
              this.classList.add('flipped');
              this.nextElementSibling.classList.add('flipped');
            }
          });
        }
      }, []);
  return (
    <>
     <section className="Menu-Section-3">
        <div className="container">
          <div className="Menu-Section-3-con">
            <div class="book">
              <div id="pages" class="pages">
                <div class="page">
                  <img src={manu1} alt="" />
                </div>
                <div class="page">
                  <img src={manu2} alt="" />
                </div>
                <div class="page">
                  <img src={manu3} alt="" />
                </div>
                <div class="page">
                  <img src={manu3} alt="" />
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Menu