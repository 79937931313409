import React, {useState, useRef} from "react";
import MetaTags from 'react-meta-tags';
import $ from "jquery";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { FaUser } from "react-icons/fa";
import "../Css/RoomDetailsPage.css";
import { FaWifi } from "react-icons/fa6";
import { FaStar } from "react-icons/fa6";
import { TbAirConditioning } from "react-icons/tb";
import { FaPersonSwimming } from "react-icons/fa6";
import { RiFridgeLine } from "react-icons/ri";
import { FaBed } from "react-icons/fa6";
import { MdBalcony } from "react-icons/md";
// import { MdBalcony } from "react-icons/md";
import { PiTelevisionSimpleBold } from "react-icons/pi";
// import { TbAirConditioning } from "react-icons/tb";

import { TbBrandAirtable } from "react-icons/tb";
import { MdOutlineBathroom } from "react-icons/md";
// import { TbBrandAirtable } from "react-icons/tb";

// import Footer from "../../Component/Footer/FooterSec";
// import demo from "../../Images/Banner/DJI_0592.JPG";
import { Link, useLocation } from "react-router-dom";
import propertyData from "../Asserts/PropertyData";
// import Room from "../../Images/Rooms/ABP_0222.jpg";
// import Room01 from "../../Images/Rooms/ABP_0323.jpg";
// import Room02 from "../../Images/Rooms/ABP_8855.jpg";
// import Room03 from "../../Images/Rooms/ABP_8953.jpg";
// import Room04 from "../../Images/Rooms/ABP_0226.jpg";

import { Progress } from "antd";
import RoomSlider from "./RoomSlider";
import BreadCrumb from "./BreadCrumb";
// import VillaSlider from './VillaSlider';
const RoomDetailsPage = () => {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const serach = useLocation();
  // originalString.split(delimiter)
  const formRef = useRef(null)
  const [custName,serCustName] =useState("")
  const [custEmail,setCustEmail] = useState("")
  const [cusContact,setCustContact] = useState("")
  const [custSubject,setCustSubject] = useState("")
  const [custMessage, setCustMessage] = useState("")
  const [formError, setFormError] = useState(false)

  const ContactEnquiry =(e)=>{
    e.preventDefault();
    if(!custName || !custEmail || !custSubject ||  !custMessage || !cusContact ){
      setFormError(true)
      return;
    }
        // Reset form error state
        setFormError(false);

        // Your form submission logic goes here
        var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">Coconut County</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${cusContact}</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left">${custSubject}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Coconut County</p></div></body></html>`;
    
        $.post(
          "https://skdm.in/server/v1/send_lead_mail.php",
          {
            toEmail: "suryawanshiresort@gmail.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Customer Lead",
            mailBody: body,
            accountName: "coconutcounty",
            accountLeadSource: "https://skdm.in",
            accountLeadName: custName,
          },
          function (dataa, status) {
            console.log("data :" + dataa);
          }
        );
    
        // Reset the form
        if (formRef.current) {
          formRef.current.reset();
        }
    
        alert("Your Form has Submitted. Our team will contact you soon.");
    
  }

  const path = serach.pathname;
  console.log(path, "path");
  const delimiter = "/";
  const resultArray = path.split(delimiter);
  console.log(resultArray);
  const propertyType = "/" + resultArray[1];
  const propertyName = "/" + resultArray[2];
  // // const resultArray = serach.split('/')
  const villaDetails = propertyData.find(
    (value) => value.slugs === propertyName
  );
  // console.log(villaDetails);
  const canonical = `${window.location.origin}${path}`
  // console.log(canonical);
  // const villaDetails = propertyData.find((v,i)=> {v.hotelList.map((e)=> e.slugs === path && return e)})
  // console.log(propertyData.map((v,i)=> {v.hotelList.find((e)=> e.slugs == path)}));
  // console.log(villaDetails.propertyName,"villaDetails");
  return (
    // villaDetails && villaDetails.hotelList.filter((value) => value.slugs === propertyName).map((valueData) => (
    <>
     <MetaTags>
        <title>{villaDetails.title}</title>
        <meta title= {`${villaDetails.title}`} />
        <meta
          name="description"
          content={villaDetails.description}
        />
        <meta
          name="keywords"
          content="Best Resort in Nashik, Coconut County in Nashik,Nature Resort near Nashik,Best Hotel In Nashik,Hotel Near Nashik With Pool,Resort Near Nashik,Top Resort in Nashik,Best Villa in Nashik,Resort with Deluxe Rooms in Nashik, Top Resort with Deluxe Rooms in Nashik ,Best Resort with Deluxe Rooms in Nashik,Resort with Meeting Hall in Nashik,Best Resort with Party Hall in Nashik"
        />
        <link rel="canonical" href={canonical} />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content= {`${villaDetails.title}`}
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonical} />
        <meta
          property="og:description"
          content={villaDetails.description}
        />
        <meta
          property="og:image"
          content="https://coconutcounty.in/static/media/JMS.7493f202fc387288d7c0.png"
        />
      </MetaTags>
      <BreadCrumb property={"property"} pageName={villaDetails.keywords} slugs={villaDetails.propertyName}  />
      {/* <section className='Rooms-Section-Banner-Images'>
                <div className='Rooms-Section-Banner-Images-con'>
                    <h2>Room</h2>
                </div> 
            </section> */}
      <div className="container">
        
        <div className="row">
            <div className="col-md-6">
            <div className="imagesectionn">
          <RoomSlider images={villaDetails.propertyImage} />
          {/* <VillaSlider images={valueData.propertyImage} /> */}
          {/* <div className="row">
                        <div className="col-6">
                            <div className='Image-Section-Room-Details-con'>
                                <img className='image-frst' src="" alt="" />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col-6">
                                    <div className='Image-Section-Room-Details-con-Second'>
                                        <img className='image-frst' src="" alt="" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='Image-Section-Room-Details-con-Second'>
                                        <img className='image-frst' src="" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className='Image-Section-Room-Details-con-third'>
                                        <img className='image-frst' src="" alt="" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='Image-Section-Room-Details-con-third'>
                                        <img className='image-frst' src="" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
            </div>
            <div className="col-md-6">
            <div className="top-bottom">
          <div className="row">
            <div className="col-12">
            <div className="top-bottom">
          <div className="row">
            <div className="col text-right">
              <div className="Room-Details-Head-Main-con">
                <h2 className="text-black">{villaDetails.propertyName}</h2>
                <p style={{ color: "orange" }}>
                  <FaStar /> <FaStar /> <FaStar /> <FaStar />
                  <FaStar />{" "}
                </p>
              </div>
            </div>
            <div className="col text-left">
              <div className="Room-Details-Head-Details-con">
                {/* <p className="Room-Details-Page-Card-Main-Price">$300/Night</p> */}
                {villaDetails.propertyName == "Party Hall"?(<></>):(<h4>{villaDetails.propertyPrice}</h4>)}
                <div className="Room-Page-card-book-Btn">
                      {/* <Link to="/Contact" > */}
                        <button variant="primary" onClick={handleShow} className="text-muted bg-black">Enquiry Now</button>
                        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enquiry Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="book-left-content input_form" bis_skin_checked={1}>
        <form
          id="contactForm"
          ref={formRef}
          action=""
          method="post"
          // noValidate="novalidate"
        >
          <div className="row" bis_skin_checked={1}>
            <div
              className="col-lg-6 col-md-6 col-sm-12 m0 col-xs-12"
              bis_skin_checked={1}
            >
              <span>Your Name</span>
              <input
                id="name"
                type="text"
                name="name"
                placeholder="Your name"
                className="form-control"
                onChange={(e)=> serCustName(e.target.value)}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 m0 col-xs-12"
              bis_skin_checked={1}
            >
              <span>Your Email</span>
              <input
                id="email"
                type="email"
                name="email"
                placeholder="Your Email"
                onChange={(e)=> setCustEmail(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          <div className="row" bis_skin_checked={1}>
          <div
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
              bis_skin_checked={1}
            >
              <span>Phone Number</span>
              <input
                id="PhoneNumber"
                type="tel"
                name="PhoneNumber"
                placeholder="Phone Number"
                className="form-control"
                onChange={(e)=> setCustContact(e.target.value)}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
              bis_skin_checked={1}
            >
              <span>Subject</span>
              <input
                id="subject"
                type="text"
                name="subject"
                placeholder="Subject"
                className="form-control"
                onChange={(e)=> setCustSubject(e.target.value)}
              />
            </div>
          </div>
          <div className="row" bis_skin_checked={1}>
            <div
              className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
              bis_skin_checked={1}
            >
              <span>Message</span>
              <textarea
                id="message"
                rows={6}
                name="message"
                placeholder="Message"
                className="form-control"
                onChange={(e)=> setCustMessage(e.target.value)}
                defaultValue={""}
              />
            </div>
          </div>
          {formError && (
            <p style={{ color:"red"}}>Please fill in all required fields</p>
          )}
          <div className="row" bis_skin_checked={1}>
            <div
              className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
              bis_skin_checked={1}
            >
              <button type="submit" onClick={ContactEnquiry} value="submit now" className="res-btn">
                Submit Now
              </button>
            </div>
          </div>
        </form>
        {/* <div id="success" bis_skin_checked={1}>
          <p>Your message sent successfully.</p>
        </div>
        <div id="error" bis_skin_checked={1}>
          <p>Something is wrong. Message cant be sent!</p>
        </div> */}
      </div>
        </Modal.Body>
       
                        </Modal>
                      {/* </Link> */}
                    </div>
              </div>
            </div>
          </div>
        </div>
              <div className="Room-Details-Page-Description">
                <h3>Description</h3>
                <p>
                 {villaDetails.propertyDetails}
                </p>
              </div>
              <div className="Room-Details-Page-Description">
                <h3>Room Aminity</h3>
                <div className="row">
                
                {villaDetails.propertyName == "Deluxe Room One" || villaDetails.propertyName == "Deluxe Room Two" || villaDetails.propertyName =="Deluxe Room Three" ||  villaDetails.propertyName =="Villa"? ( <div className='Rooms-page-card-RoomsDetails'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='Rooms-page-card-RoomsDetails-Icon-And-Name-con'>
                                                    <div className='row'>
                                                        {/* <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <IoWifi />
                                                                <h6>inclusive wifi</h6>
                                                            </div>
                                                        </div> */}
                                                        <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                {/* <FaRegUser /> */} <TbAirConditioning />
                                                                <h6>AC</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                {/* <LuScale3D /> */} <MdBalcony />
                                                                <h6>Balcony</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <FaBed />
                                                                <h6>Bedding</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                {/* <FaBed /> */} <PiTelevisionSimpleBold />
                                                                <h6>TV</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                {/* <FaBed /> */} <TbBrandAirtable />
                                                                <h6>Furniture </h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                {/* <FaBed /> */} <MdOutlineBathroom />
                                                                <h6>Bathroom</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>) :  <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='Rooms-page-card-RoomsDetails-Icon-And-Name-con'>
                                                    <div className='row'>
                                                        
                                                        <div>
                                                        <div className='col-md-12'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                            <FaUser />
                                                              {villaDetails.capacity? (<h6>{villaDetails.capacity}</h6>):(<p></p>) }
                                                            </div>
                                                        </div>
                                                        {/* <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <MdBalcony />
                                                                <h6>Balcony</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <FaBed />
                                                                <h6>Bedding</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                 <PiTelevisionSimpleBold />
                                                                <h6>TV</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                               <TbBrandAirtable />
                                                                <h6>Furniture </h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <MdOutlineBathroom />
                                                                <h6>Bathroom</h6>
                                                            </div>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>}
                </div>
              </div>
            </div>
      
          </div>
        </div>
            </div>
        </div>
        
      </div>

      {/* <Footer /> */}
    </>
    // ))
  );
};

export default RoomDetailsPage;
