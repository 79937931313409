import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import Contact from './Pages/Contact';
import AboutUs from './Components/AboutUs/AboutUs';
import  Footer  from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Room from './Pages/Room';
import PropertyData from "./Asserts/PropertyData";
import RoomDetailsPage from './Pages/RoomDetailsPage';
import Dinning from './Pages/Dinning';
import NavGallery from './Pages/NavGallery';
import ErrorPage from './Pages/ErrorPage';
import Whatapp from "./Pages/whatapp";
function App() {
  return (
    <>
    <BrowserRouter>
    <Header />
    <Whatapp />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/coconut-county-nashik' element={<AboutUs />} />
        <Route path='/resort-near-nashik' element={<Contact />} />
        <Route path='/top-resort-nashik' element={<Room />}/>
        <Route path='/hotel-near-nashik-with-pool' element={<NavGallery />} />
        {PropertyData.map((v,i)=>{
          return(
            <>
            <Route 
            key={i}
            exact
            path={`/top-resort-nashik${v.slugs}`}
            element={<RoomDetailsPage />}/>
            </>
          )
        })}
        <Route path='/best-hotel-nashik' element={<Dinning />} />
        <Route path='/*' element={<ErrorPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>

    </>
  );
}

export default App;
