import React from 'react';
import { FaUser } from "react-icons/fa";

import "../Css/RoomCard.css";
import { FaStar } from "react-icons/fa6";
import { LuScale3D } from "react-icons/lu";
import { IoWifi } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa6";
import { FaBed } from "react-icons/fa6";
import { MdBalcony } from "react-icons/md";
import { PiTelevisionSimpleBold } from "react-icons/pi";
import { TbBrandAirtable } from "react-icons/tb";
import { MdOutlineBathroom } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";

import { Link } from 'react-router-dom';

const RoomCard = ({ name,slugs,img,capacity,details,price }) => {
    return (
        <>

            <section className='Rooms-Page-Section-Card-con '>
                <div className='container'>
                    <div className='Rooms-Page-Section-Card shadow-sm p-3 mb-5 bg-white rounded'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className='Room-Page-Section-card-Image'>
                                    <img src={img} alt='Top Resort in Nashik' />
                                </div>
                            </div>
                            <div className='col-md-9'>
                                <div className='Room-Page-Section-Card-Details-con'>

                                    <div className='Room-Page-Card-Name-And-Reviews-Card'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='Room-Page-card-Name-And-Star-con'>
                                                    <h2>{name}</h2>
                                                    <div className='Room-Page-card-Star-con ' style={{ color: "orange" }}>
                                                        <div className='Room-Oage-card-Star starcolor' >
                                                            <FaStar />
                                                        </div>
                                                        <div className='Room-Oage-card-Star starcolor'>
                                                            <FaStar />
                                                        </div>
                                                        <div className='Room-Oage-card-Star starcolor'>
                                                            <FaStar />
                                                        </div>
                                                        <div className='Room-Oage-card-Star starcolor'>
                                                            <FaStar />
                                                        </div>
                                                        <div className='Room-Oage-card-Star'>
                                                            <FaStar />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='Rooms-page-Card-reviews-Con'>
                                                    <div className='Rooms-page-Card-reviews-Number'>
                                                        {/* <h3>Very Good</h3>
                                                        <h4>5 review</h4> */}
                                                    </div>
                                                    <div className='Rooms-page-Card-reviews-Number-Rate'>
                                                        <h5>5 start</h5>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='Rooms-page-card-Details-Para'>
                                        <p className='property-describtion'>{details}</p>
                                    </div>

                                   {name == "Deluxe Room One" || name == "Deluxe Room Two" || name =="Deluxe Room Three" ||  name =="Villa"? ( <div className='Rooms-page-card-RoomsDetails'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='Rooms-page-card-RoomsDetails-Icon-And-Name-con mb-3'>
                                                    <div className='row row-cols-2'>
                                                        {/* <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <IoWifi />
                                                                <h6>inclusive wifi</h6>
                                                            </div>
                                                        </div> */}
                                                        <div className='col'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                {/* <FaRegUser /> */} <TbAirConditioning />
                                                                <h6>AC</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                {/* <LuScale3D /> */} <MdBalcony />
                                                                <h6>Balcony</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <FaBed />
                                                                <h6>Bedding</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                {/* <FaBed /> */} <PiTelevisionSimpleBold />
                                                                <h6>TV</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                {/* <FaBed /> */} <TbBrandAirtable />
                                                                <h6>Furniture </h6>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                {/* <FaBed /> */} <MdOutlineBathroom />
                                                                <h6>Bathroom</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='Room-Page-Card-Price-Con'>
                                                    {/* <div className='Room-Page-Card-Main-Price-con'>
                                                        <h6 className='Room-Page-Card-Main-Price'>$ 10000 / Night</h6>
                                                    </div> */}
                                                </div>
                                                <div className='Room-Page-Card-Offer-Price-Con'>
                                                    <div className='Room-Page-Card-Offer-Price-Btn'>
                                                        <button>
                                                            Offer Price
                                                        </button>
                                                    </div>
                                                    <div className='Room-Page-Card-Offer-Price'>
                                                        <h6> ₹{price}</h6>
                                                    </div>
                                                </div>
                                                <div className='Room-Page-card-book-Btn '>
                                                    <Link to={`/top-resort-nashik${slugs}`}>
                                                        <button className='bg-black'>
                                                            Know More
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>) :  <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='Rooms-page-card-RoomsDetails-Icon-And-Name-con'>
                                                    <div className='row'>
                                                        
                                                        <div>
                                                        <div className='col-md-12'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                            <FaUser />
                                                              {capacity? (<h6>{capacity}</h6>):(<p></p>) }
                                                            </div>
                                                        </div>
                                                        {/* <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <MdBalcony />
                                                                <h6>Balcony</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <FaBed />
                                                                <h6>Bedding</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                 <PiTelevisionSimpleBold />
                                                                <h6>TV</h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                               <TbBrandAirtable />
                                                                <h6>Furniture </h6>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                <MdOutlineBathroom />
                                                                <h6>Bathroom</h6>
                                                            </div>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='Room-Page-Card-Price-Con'>
                                                    <div className='Room-Page-Card-Main-Price-con'>
                                                        {/* <h6 className='Room-Page-Card-Main-Price'>$ 10000 / Night</h6> */}
                                                    </div>
                                                </div>
                                               {name== "Party Hall"? (<></>):( <div className='Room-Page-Card-Offer-Price-Con'>
                                                    <div className='Room-Page-Card-Offer-Price-Btn'>
                                                        <button>
                                                            Offer Price
                                                        </button>
                                                    </div>
                                                    <div className='Room-Page-Card-Offer-Price'>
                                                        <h6>₹{price} </h6>
                                                    </div>
                                                </div>)}
                                                <div className='Room-Page-card-book-Btn '>
                                                    <Link to={`/top-resort-nashik${slugs}`}>
                                                        <button className='bg-black'>
                                                            Know More
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>}


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}

export default RoomCard