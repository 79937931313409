import React, {useRef, useState} from 'react'
import MetaTags from 'react-meta-tags';
import $ from "jquery";
import banner2 from "../Asserts/banner2.jpg";
import BreadCrumb from './BreadCrumb';
const Contact = () => {
  const formRef = useRef(null)
  const [custName,serCustName] =useState("")
  const [custEmail,setCustEmail] = useState("")
  const [cusContact,setCustContact] = useState("")
  const [custSubject,setCustSubject] = useState("")
  const [custMessage, setCustMessage] = useState("")
  const [formError, setFormError] = useState(false)

  const ContactEnquiry =(e)=>{
    e.preventDefault();
    if(!custName || !custEmail || !custSubject ||  !custMessage || !cusContact ){
      setFormError(true)
      return;
    }
        // Reset form error state
        setFormError(false);

        // Your form submission logic goes here
        var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">Coconut County</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${cusContact}</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left">${custSubject}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Coconut County</p></div></body></html>`;
    
        $.post(
          "https://skdm.in/server/v1/send_lead_mail.php",
          {
            toEmail: "suryawanshiresort@gmail.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Customer Lead",
            mailBody: body,
            accountName: "coconutcounty",
            accountLeadSource: "https://skdm.in",
            accountLeadName: custName,
          },
          function (dataa, status) {
            console.log("data :" + dataa);
          }
        );
    
        // Reset the form
        if (formRef.current) {
          formRef.current.reset();
        }
    
        alert("Your Form has Submitted. Our team will contact you soon.");
    
  }

  return (
   
    <>
      <MetaTags>
        <title>Resort Near Nashik | Coconut County Resort</title>
        <meta title="Resort Near Nashik | Coconut County Resort" />
        <meta
          name="description"
          content="Escape to Coconut County Resort, a premier destination near Nashik, offering luxury, tranquility, and natural beauty for an unforgettable getaway."
        />
        <meta
          name="keywords"
          content="Best Resort in Nashik, Coconut County in Nashik,Nature Resort near Nashik,Best Hotel In Nashik,Hotel Near Nashik With Pool,Resort Near Nashik,Top Resort in Nashik,Best Villa in Nashik,Resort with Deluxe Rooms in Nashik, Top Resort with Deluxe Rooms in Nashik ,Best Resort with Deluxe Rooms in Nashik,Resort with Meeting Hall in Nashik,Best Resort with Party Hall in Nashik"

        />
        <link rel="canonical" href="https://coconutcounty.in/resort-near-nashik" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content="Resort Near Nashik | Coconut County Resort"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://coconutcounty.in/resort-near-nashik" />
        <meta
          property="og:description"
          content="Escape to Coconut County Resort, a premier destination near Nashik, offering luxury, tranquility, and natural beauty for an unforgettable getaway."
        />
        <meta
          property="og:image"
          content="https://coconutcounty.in/static/media/JMS.7493f202fc387288d7c0.png"
        />
      </MetaTags>
    <BreadCrumb pageName="Resort Near Nashik" slugs="Contact Us" />
<section className="container clearfix common-pad booknow ">
<p className='d-none'>
Welcome to our resort's Contact Us page, a resort near Nashik,where connecting with us is as seamless as the experience we offer at our retreat. Your inquiries and feedback are paramount to us, and we're eager to assist in ensuring your stay exceeds expectations.

Our dedicated team is at your service, ready to address any queries or assist with reservations. Whether you have specific accommodation preferences, dining requests, or special arrangements, resort near Nashik are here to personalize your experience and make your stay memorable.

For reservation inquiries, our contact details are provided below. Our reservation specialists are well-versed in tailoring packages to suit your needs, ensuring a bespoke stay tailored just for you.

If you have any general inquiries or feedback, our team welcomes your input. We value your thoughts on our resort near Nashik and continually strive to enhance our offerings based on your experiences. Your feedback is integral to our commitment to providing an exceptional retreat amidst nature's beauty.

Feel free to reach out through the contact form provided, and our responsive team will promptly attend to your needs. Additionally, our social media channels are a dynamic platform to stay updated on the latest resort news, promotions, and engage with our community.

Visit us, call, or send us a message – we're here to make your stay seamless, enjoyable, and unforgettable. Thank you for considering our resort near Nashik for your retreat, and we look forward to assisting you in creating lasting memories amidst the tranquility and luxury of our 37-acre haven.
</p>
  <div className="sec-header custom-padding " bis_skin_checked={1}>
    <h2>Send a message</h2>
    <h3>If you have any query, feel free to contact us. We would love to help you.</h3>
  </div>
  <div className="row nasir-contact" bis_skin_checked={1}>
    <div className="col-md-8" bis_skin_checked={1}>
      <div className="book-left-content input_form" bis_skin_checked={1}>
        <form
          id="contactForm"
          ref={formRef}
          action=""
          method="post"
          // noValidate="novalidate"
        >
          <div className="row" bis_skin_checked={1}>
            <div
              className="col-lg-6 col-md-6 col-sm-12 m0 col-xs-12"
              bis_skin_checked={1}
            >
              <span>Your Name</span>
              <input
                id="name"
                type="text"
                name="name"
                placeholder="Your name"
                className="form-control"
                onChange={(e)=> serCustName(e.target.value)}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 m0 col-xs-12"
              bis_skin_checked={1}
            >
              <span>Your Email</span>
              <input
                id="email"
                type="email"
                name="email"
                placeholder="Your Email"
                onChange={(e)=> setCustEmail(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          <div className="row" bis_skin_checked={1}>
          <div
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
              bis_skin_checked={1}
            >
              <span>Phone Number</span>
              <input
                id="PhoneNumber"
                type="tel"
                name="PhoneNumber"
                placeholder="Phone Number"
                className="form-control"
                onChange={(e)=> setCustContact(e.target.value)}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
              bis_skin_checked={1}
            >
              <span>Subject</span>
              <input
                id="subject"
                type="text"
                name="subject"
                placeholder="Subject"
                className="form-control"
                onChange={(e)=> setCustSubject(e.target.value)}
              />
            </div>
          </div>
          <div className="row" bis_skin_checked={1}>
            <div
              className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
              bis_skin_checked={1}
            >
              <span>Message</span>
              <textarea
                id="message"
                rows={6}
                name="message"
                placeholder="Message"
                className="form-control"
                onChange={(e)=> setCustMessage(e.target.value)}
                defaultValue={""}
              />
            </div>
          </div>
          {formError && (
            <p style={{ color:"red"}}>Please fill in all required fields</p>
          )}
          <div className="row" bis_skin_checked={1}>
            <div
              className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
              bis_skin_checked={1}
            >
              <button type="submit" onClick={ContactEnquiry} value="submit now" className="res-btn">
                Submit Now
              </button>
            </div>
          </div>
        </form>
        {/* <div id="success" bis_skin_checked={1}>
          <p>Your message sent successfully.</p>
        </div>
        <div id="error" bis_skin_checked={1}>
          <p>Something is wrong. Message cant be sent!</p>
        </div> */}
      </div>
    </div>
    <div className="col-md-4 custom-padding " bis_skin_checked={1}>
      <div className="contact-info" bis_skin_checked={1}>
        <h2>Contact Info</h2>
        <div className="media-contact clearfix" bis_skin_checked={1}>
          <div className="media-contact-icon" bis_skin_checked={1}>
            <i aria-hidden="true" className="fa fa-map-marker" />
          </div>
          <div className="media-contact-info" bis_skin_checked={1}>
            <p>
            Murambi, Wadiwarhe-Ahurli,Road, Trimbak, road, Nashik, Maharashtra 422403
            </p>
          </div>
        </div>
        <div className="media-contact clearfix" bis_skin_checked={1}>
          <div className="media-contact-icon" bis_skin_checked={1}>
            <i aria-hidden="true" className="fa fa-envelope-o" />
          </div>
          <div className="media-contact-info" bis_skin_checked={1}>
            <p>
              <a href="mailto:suryawanshiresort@gmail.com">suryawanshiresort@gmail.com</a>
              {/* <br />
              <a href="mailto:support@Resorthotel.Com">
                support@Resorthotel.Com
              </a> */}
            </p>
          </div>
        </div>
        <div className="media-contact clearfix" bis_skin_checked={1}>
          <div className="media-contact-icon" bis_skin_checked={1}>
            <i aria-hidden="true" className="fa fa-phone" />
          </div>
          <div className="media-contact-info" bis_skin_checked={1}>
          <p>
              <a href="tel:8668207978">
                <i>8668207978</i>
              </a>
              <br />
              <a href="tel:8421974306">
                <i>8421974306</i>
              </a>
            </p>
          </div>
        </div>
        {/* <div className="media-contact clearfix" bis_skin_checked={1}>
          <div className="media-contact-icon" bis_skin_checked={1}>
            <i aria-hidden="true" className="icon icon-Timer" />
          </div>
          <div className="media-contact-info" bis_skin_checked={1}>
            <p>
              <a href="tel:18005622487">
                <i>+ 1800 562 2487</i>
              </a>
              <br />
              <a href="tel:32155468975">
                <i>+ 3215 546 8975</i>
              </a>
            </p>
          </div>
        </div> */}
      </div>
    </div>
  </div>
</section>
<section>
<div
className='container'
  bis_skin_checked={1}
>
  <div className="gm-err-container" bis_skin_checked={1}>
    <div className="gm-err-content" bis_skin_checked={1}>
      <div className="gm-err-icon" bis_skin_checked={1}>
      <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3752.9890598467227!2d73.63616847581943!3d19.840415827529718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdd8f44122c59f9%3A0x8bafbe132a25decc!2sCoconut%20County%20resort!5e0!3m2!1sen!2sin!4v1708084897894!5m2!1sen!2sin"
  width="100%"
  height={450}
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

      </div>
      {/* <div className="gm-err-title" bis_skin_checked={1}>
        Oops! Something went wrong.
      </div>
      <div className="gm-err-message" bis_skin_checked={1}>
        This page didn't load Google Maps correctly. See the JavaScript console
        for technical details.
      </div> */}
    </div>
  </div>
</div>
</section>



    </>
  )
}

export default Contact