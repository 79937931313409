import villa1 from "../Asserts/coconutImagesWebp/coco(15).webp";
import villa2 from "../Asserts/coconutImagesWebp/coco(4).webp";
import villa3 from "../Asserts/coconutImagesWebp/coco(21).webp";
import villa4 from "../Asserts/coconutImagesWebp/coco(86).webp";
import villa5 from "../Asserts/coconutImagesWebp/coco(22).webp";

import roomOne1 from "../Asserts/coconutImagesWebp/coco(36).webp";
import roomOne2 from "../Asserts/coconutImagesWebp/coco(37).webp";
import roomOne3 from "../Asserts/coconutImagesWebp/coco(40).webp";
import roomOne4 from "../Asserts/coconutImagesWebp/coco(43).webp";

import roomTwo1 from "../Asserts/coconutImagesWebp/coco(74).webp";
import roomTwo2 from "../Asserts/coconutImagesWebp/coco(75).webp";
import roomTwo3 from "../Asserts/coconutImagesWebp/coco(76).webp";
import roomTwo4 from "../Asserts/coconutImagesWebp/coco(77).webp";

import MeetingHall1 from "../Asserts/coconutImagesWebp/coco(54).webp";
import MeetingHall2 from "../Asserts/coconutImagesWebp/coco(58).webp";
import MeetingHall3 from "../Asserts/coconutImagesWebp/coco(70).webp";

import PartyHall1 from "../Asserts/coconutImagesWebp/coco(81).webp";
import PartyHall2 from "../Asserts/coconutImagesWebp/coco(98).webp";
import PartyHall3 from "../Asserts/coconutImagesWebp/coco(99).webp";
import PartyHall4 from "../Asserts/coconutImagesWebp/coco(97).webp";

const PropertyData = 
[
  {
    propertyName: "Villa",
    slugs: "/best-villa-nashik",
    propertyPrice:19999,
    propertyDetails:"Discover the epitome of luxury living at our resort, offering the best villas in Nashik. Our exquisite villas redefine elegance and comfort, providing a haven of tranquility amidst breathtaking surroundings. Each villa is meticulously designed to exceed expectations, boasting lavish amenities and unparalleled attention to detail. Immerse yourself in the ultimate retreat experience as you unwind in the spacious interiors and soak in panoramic views. Whether you seek a romantic getaway, a family escape, or a serene solo retreat, our villas promise an unforgettable stay. Experience the best villa in Nashik and elevate your vacation to new heights of indulgence and relaxation.    ",
    description:"Experience unparalleled luxury at the Best villa in Nashik. Indulge in exquisite elegance, where every detail is designed for your utmost comfort and pleasure. ",
    title: "Best Villa in Nashik | Coconut County Resort",
    keywords:"Best Villa in Nashik",
    propertyImage: [
      villa1 , villa2,villa3,villa4,villa5
    ],
  },
  {
    propertyName: "Deluxe Room One",
    slugs: "/resort-deluxe-rooms-nashik",
    propertyPrice:"3999/per room",
    propertyDetails:"Experience unparalleled comfort and luxury in our deluxe rooms at our resort in Nashik. Each deluxe room is thoughtfully designed to provide the utmost relaxation and convenience for our guests. Indulge in modern amenities and tasteful décor, ensuring a memorable stay. Whether you're visiting for business or leisure, our deluxe rooms offer the perfect retreat after a day of exploration in Nashik. With attention to detail and exceptional service, our deluxe rooms exemplify the essence of hospitality. Enjoy a truly rejuvenating experience in our resort's deluxe rooms, making it the ideal choice for your stay in Nashik.    ",
    description:"Experience unparalleled luxury at our Resort with Deluxe Rooms in Nashik. Immerse yourself in opulence & comfort, ensuring a lavish escape.",
    title: "Resort with Deluxe Rooms in Nashik | Coconut County Resort",
    keywords:"Resort with Deluxe Rooms in Nashik",
    propertyImage: [
      roomOne1,roomOne2,roomOne3,roomOne4
    ],
  },
  {
    propertyName: "Deluxe Room Two",
    slugs: "/top-resort-deluxe-rooms-nashik",
    propertyPrice:"3999/per room",
    propertyDetails:"Immerse yourself in luxury at our top resort in Nashik, featuring deluxe rooms designed for unparalleled comfort. Each deluxe room is meticulously crafted to offer a blend of sophistication and relaxation, ensuring a memorable stay for our guests. Indulge in modern amenities and elegant furnishings, creating a serene retreat amidst the beauty of Nashik. Whether you're here for business or leisure, our deluxe rooms provide the perfect sanctuary after a day of exploration. With exceptional service and attention to detail, our resort stands out as the top choice for travelers seeking deluxe accommodations in Nashik. Experience the epitome of luxury at our top resort with deluxe rooms in Nashik.    ",
    description:"Embark on a journey of lavish indulgence at the Top Resort with Deluxe Rooms in Nashik. Experience unparalleled luxury and comfort at Coconut County.  ",
    title: " Top Resort with Deluxe Rooms in Nashik | Coconut County ",
    keywords:" Top Resort with Deluxe Rooms in Nashik ",
    propertyImage: [
      roomTwo1,roomTwo2,roomTwo3,roomTwo4
    ],
  },
  {
    propertyName: "Deluxe Room Three",
    slugs: "/best-resort-deluxe-rooms-nashik",
    propertyPrice:"3999/per room",
    propertyDetails:"Discover unparalleled luxury at the best resort in Nashik, offering deluxe rooms designed for your utmost comfort and satisfaction. Each deluxe room exudes elegance and sophistication, providing a serene retreat amidst the scenic beauty of Nashik. Immerse yourself in modern amenities and tasteful decor, ensuring a memorable and rejuvenating stay. Whether you're visiting for business or leisure, our deluxe rooms offer the perfect blend of convenience and luxury. With exceptional service and attention to detail, our resort stands out as the best choice for travelers seeking deluxe accommodations in Nashik. Experience the pinnacle of luxury at our best resort with deluxe rooms in Nashik.",
    description:"Indulge in the epitome of luxury at the Best Resort with Deluxe Rooms in Nashik. Experience a lavish escape at Coconut  County Resort.",
    title: "Best Resort with Deluxe Rooms in Nashik | Coconut County ",
    keywords:"Best Resort with Deluxe Rooms in Nashik",
    propertyImage: [
      roomTwo1,roomTwo2,roomTwo3,roomTwo4
    ],
  },
  {
    propertyName: "Meeting Hall",
    slugs: "/resort-meeting-hall-nashik",
    propertyPrice:"9999/-",
    propertyDetails:"Discover the ideal venue for your events at our resort in Nashik, featuring a versatile meeting hall designed to accommodate various gatherings. Our meeting hall offers state-of-the-art facilities and customizable setups, ensuring a seamless experience for your conferences, seminars, or special occasions. With ample space and modern amenities, our meeting hall provides the perfect backdrop for productive meetings or memorable celebrations. Whether you're hosting a corporate event or a social gathering, our resort's meeting hall in Nashik caters to your needs with professionalism and efficiency. Elevate your events at our resort with a sophisticated meeting hall in Nashik.    ",
    description:"Discover the ideal blend of business and comfort at our Resort with a Meeting Hall in Nashik. Elevate your corporate gatherings in a sophisticated setting.",
    title: "Resort with Meeting Hall in Nashik | Coconut County Resort",
    keywords:"Resort with Meeting Hall in Nashik",
    capacity:"40 Person Capacity",
    propertyImage: [
      MeetingHall1,
      MeetingHall2,
      MeetingHall3
    ],
  },
  {
    propertyName: "Party Hall",
    slugs: "/best-resort-party-hall-nashik",
    propertyPrice:0,
    propertyDetails:"Discover the ideal venue for your events at our resort in Nashik, featuring a versatile meeting hall designed to accommodate various gatherings. Our meeting hall offers state-of-the-art facilities and customizable setups, ensuring a seamless experience for your conferences, seminars, or special occasions. With ample space and modern amenities, our meeting hall provides the perfect backdrop for productive meetings or memorable celebrations. Whether you're hosting a corporate event or a social gathering, our resort's meeting hall in Nashik caters to your needs with professionalism and efficiency. Elevate your events at our resort with a sophisticated meeting hall in Nashik.",
    description:"Elevate your events to unforgettable heights at the Best Resort with a Party Hall in Nashik. Experience unmatched luxury & seamless celebrations.",
    title: "Best Resort with Party Hall in Nashik | Coconut County Resort",
    keywords:"Best Resort with Party Hall in Nashik",
    capacity:"100 Person Seating Capacity",
    propertyImage: [
      PartyHall1,PartyHall2,PartyHall3,PartyHall4
    ],
  },

];

export default PropertyData;
