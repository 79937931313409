import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import banner from "../Asserts/banner.jpg";
import "../Css/BannerSlider.css"
function BannerSlider() {
  return (
    <Carousel>
        
        <Carousel.Item  id="firstSlide">
          {/* <Zoom left cascade> */}
          {/* <img className="d-block w-100 h-100" src={banner1} alt="" /> */}
          
          {/* </Zoom> */}
          <Carousel.Caption>
            <div className="overlay1">
            <div className="container slider-caption">
              <h5 className="subtitle">Your Gateway to Green Living </h5>
              <h1 className='text-white'>Best Resort in Nashik</h1>
              <p>
              Resorthouse in Resort offers a harmonious blend of tranquility and luxury as you are surrounded by breathtaking landscapes 
              </p>
              <Link to="/coconut-county-nashik">
              <a
                className="btn btn-default btn-outline"
                href="/coconut-county-nashik"
                role="button"
              >
                LEARN MORE
              </a>
              </Link>
            </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item id="secondSlide">
          {/* <Zoom> */}

          {/* <img
            className="d-block w-100 h-100"
            src={banner2}
            alt="invisible braces in mumbai"
          /> */}
          {/* </Zoom> */}
          <Carousel.Caption>
            <div className="container slider-caption">
              <h5 className="subtitle">Where Every Stay is Green </h5>
              <h2>Feel the real beauty of nature</h2>
              <p>
              At Resorthouse in Resort, experience a seamless fusion of serenity and opulence amidst stunning landscapes              </p>
              <Link to="/coconut-county-nashik">
              <a
                className="btn btn-default btn-outline"
                href="/coconut-county-nashik"
                role="button"
              >
                LEARN MORE
              </a>
              </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item id="thirdSlide">
          {/* <Zoom> */}
          {/* <img className="d-block w-100 h-100" src={banner3} alt="" /> */}
          {/* </Zoom> */}

          <Carousel.Caption>
            <div className="container slider-caption">
              <h5 className="subtitle">Your Eco-Friendly Retreat</h5>
              <h2>Plant tree and save nature</h2>
              <p>
              Resorthouse within the resort provides a perfect balance of serenity and luxury amidst breathtaking landscapes. 
              </p>
              <Link to='/coconut-county-nashik'>
              <a
                className="btn btn-default btn-outline"
                href="/coconut-county-nashik"
                role="button"
              >
                LEARN MORE
              </a>
              </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
  );
}

export default BannerSlider;