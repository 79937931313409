import gallery0 from "../Asserts/coconutImagesWebp/coco(104).webp";
import gallery1 from "../Asserts/coconutImagesWebp/coco(101).webp";
import gallery2 from "../Asserts/coconutImagesWebp/coco(102).webp";
import gallery3 from "../Asserts/coconutImagesWebp/coco(2).webp";
import gallery4 from "../Asserts/coconutImagesWebp/coco(3).webp";
import gallery5 from "../Asserts/coconutImagesWebp/coco(6).webp";
import gallery6 from "../Asserts/coconutImagesWebp/coco(13).webp";
import gallery7 from "../Asserts/coconutImagesWebp/coco(14).webp";
import gallery8 from "../Asserts/coconutImagesWebp/coco(20).webp";
import gallery9 from "../Asserts/coconutImagesWebp/coco(25).webp";
import gallery10 from "../Asserts/coconutImagesWebp/coco(27).webp";
import gallery11 from "../Asserts/coconutImagesWebp/coco(31).webp";
import gallery12 from "../Asserts/coconutImagesWebp/coco(47).webp";
import gallery13 from "../Asserts/coconutImagesWebp/coco(48).webp";
import gallery14 from "../Asserts/coconutImagesWebp/coco(50).webp";
import gallery15 from "../Asserts/coconutImagesWebp/coco(82).webp";
import gallery16 from "../Asserts/coconutImagesWebp/coco(88).webp";
import gallery17 from "../Asserts/coconutImagesWebp/coco(89).webp";
import gallery18 from "../Asserts/coconutImagesWebp/coco(100).webp";
import gallery19 from "../Asserts/coconutImagesWebp/coco(95).webp";
import gallery20 from "../Asserts/coconutImagesWebp/coco(4).webp";
import gallery21 from "../Asserts/coconutImagesWebp/coco(15).webp";
import gallery22 from "../Asserts/coconutImagesWebp/coco(7).webp";
import gallery23 from "../Asserts/coconutImagesWebp/coco(8).webp";
import gallery24 from "../Asserts/coconutImagesWebp/coco(9).webp";
import gallery25 from "../Asserts/coconutImagesWebp/coco(97).webp";

const GalleryData = [
  {
    id: 1,
    img: gallery0,
    alts: "coconut",
    type: "image"
  },
    {
      id: 1,
      img: gallery1,
      alts: "coconut",
      type: "image"
    },
    {
      id: 2,
      img: gallery2,
      alts: "coconut",
      type: "image"
    },
    {
      id: 3,
      img: gallery3,
      alts: "coconut",
      type: "image"
    },
    {
      id: 4,
      img: gallery4,
      alts: "coconut",
      type: "image"
    },
    {
      id: 5,
      img: gallery5,
      alts: "coconut",
      type: "image"
    },
    {
      id: 6,
      img: gallery6,
      alts: "coconut",
      type: "image"
    },
    {
      id: 7,
      img: gallery7,
      alts: "coconut",
      type: "image"
    },
    {
      id: 8,
      img: gallery8,
      alts: "coconut",
      type: "image"
    },
    {
      id: 9,
      img: gallery9,
      alts: "coconut",
      type: "image"
    },
    {
      id: 10,
      img: gallery10,
      alts: "coconut",
      type: "image"
    },
    {
      id: 11,
      img: gallery11,
      alts: "coconut",
      type: "image"
    },
    {
      id: 12,
      img: gallery12,
      alts: "coconut",
      type: "image"
    },
    {
      id: 13,
      img: gallery13,
      alts: "coconut",
      type: "image"
    },
    {
      id: 14,
      img: gallery14,
      alts: "coconut",
      type: "image"
    },
    {
        id: 14,
        img: gallery15,
        alts: "coconut",
        type: "video"
      },
      {
        id: 14,
        img: gallery16,
        alts: "coconut",
        type: "video"
      },
      {
        id: 14,
        img: gallery17,
        alts: "coconut",
        type: "video"
      },
      {
        id: 14,
        img: gallery18,
        alts: "coconut",
        type: "video"
      },
      {
        id: 14,
        img: gallery19,
        alts: "coconut",
        type: "video"
      },
      {
        id: 14,
        img: gallery20,
        alts: "coconut",
        type: "video"
      },
      {
        id: 14,
        img: gallery21,
        alts: "coconut",
        type: "video"
      },
      {
        id: 14,
        img: gallery22,
        alts: "coconut",
        type: "video"
      },
      {
        id: 14,
        img: gallery23,
        alts: "coconut",
        type: "video"
      },
      {
        id: 14,
        img: gallery24,
        alts: "coconut",
        type: "video"
      },
      {
        id: 14,
        img: gallery25,
        alts: "coconut",
        type: "video"
      },

      
    // Add more objects as needed
  ];
export default GalleryData;