import React from 'react'
import { BsWhatsapp } from 'react-icons/bs';
import "../Css/Buttions.css"
const whatapp = () => {
  return (
    <div>
      <>
            <div className='CallBtn-con'>
                <div className='CallBtn'>
                    <a href="https://wa.me/+918668207978?text=" target='blank'>
                        <BsWhatsapp/>
                    </a>
                </div>
            </div>
        </>
    </div>
  )
}

export default whatapp
