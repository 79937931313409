import React from "react";
import BreadCrumb from "./BreadCrumb";
import RoomCard from "./RoomCard";
import PropertyData from "../Asserts/PropertyData";
import MetaTags from 'react-meta-tags';

const Room = () => {
  return (
    <>
     <MetaTags>
        <title>Top Resort in Nashik | Coconut County Resort</title>
        <meta title="Top Resort in Nashik | Coconut County Resort" />
        <meta
          name="description"
          content="Indulge in opulent serenity at the Top Resort in Nashik. Our luxurious retreat offers unparalleled tranquility, ensuring an unforgettable experience."
        />
        <meta
          name="keywords"
          content="Best Resort in Nashik, Coconut County in Nashik,Nature Resort near Nashik,Best Hotel In Nashik,Hotel Near Nashik With Pool,Resort Near Nashik,Top Resort in Nashik,Best Villa in Nashik,Resort with Deluxe Rooms in Nashik, Top Resort with Deluxe Rooms in Nashik ,Best Resort with Deluxe Rooms in Nashik,Resort with Meeting Hall in Nashik,Best Resort with Party Hall in Nashik"
        />
        <link rel="canonical" href="https://coconutcounty.in/resort-near-nashik" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content="Top Resort in Nashik | Coconut County Resort"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://coconutcounty.in/resort-near-nashik" />
        <meta
          property="og:description"
          content="Indulge in opulent serenity at the Top Resort in Nashik. Our luxurious retreat offers unparalleled tranquility, ensuring an unforgettable experience."
        />
        <meta
          property="og:image"
          content="https://coconutcounty.in/static/media/JMS.7493f202fc387288d7c0.png"
        />
      </MetaTags>
      <BreadCrumb pageName="Top Resort in Nashik" slugs="Propertys" />
      <section className="container clearfix common-pad-inner about-info-box">
        <div className="row" bis_skin_checked={1}>
          <div className="col-lg-12 col-md-12 col-xs-12" bis_skin_checked={1}>
            <div className="sec-header3" bis_skin_checked={1}>
              <h2>Properties</h2>
              <h3>Explore our portfolio of exquisite properties, tailored to perfection</h3>
            </div>
            <p>
            Experience the epitome of luxury at our top resort in Nashik. Nestled amidst serene surroundings, our resort boasts a range of accommodations including luxurious villas and deluxe rooms, ensuring a comfortable and unforgettable stay for every guest. Whether you're planning a corporate event or a celebration, our meeting hall and party hall offer versatile spaces to host gatherings of any scale. Come and discover why we're considered the top resort in Nashik, where luxury, comfort, and unparalleled hospitality await you at every turn.

            </p>
          </div>
        </div>
      </section>
      {PropertyData.map((v,i)=>{
        return(
            <>
                <RoomCard slugs={v.slugs} name={v.propertyName} img={v.propertyImage[0]} capacity= {v.capacity} details={v.propertyDetails} price ={v.propertyPrice}/>
            </>
        )
      })}
    </>
  );
};

export default Room;
