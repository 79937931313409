import React from "react";
import MetaTags from "react-meta-tags";
import { Link } from 'react-router-dom';
import BreadCrumb from "../../Pages/BreadCrumb";
import Gallery from "../../Pages/Gallery";
import { GiGrassMushroom } from "react-icons/gi";
import { FaAward } from "react-icons/fa6";
import { FcCustomerSupport } from "react-icons/fc";
import dining from "../../Asserts/coconutImagesWebp/coco(95).webp";
import about from "../../Asserts/coconutImagesWebp/coco(14).webp";
import resort from "../../Asserts/banner3.webp";

const AboutUs = () => {
  return (
    <>
      <MetaTags>
        <title>Coconut County in Nashik | Coconut County Resort</title>
        <meta title="Coconut County in Nashik | Coconut County Resort" />
        <meta
          name="description"
          content="Coconut County in Nashik is a serene getaway, offering relaxation and adventure amidst nature at Coconut County Resort. Discover tranquility today!"
        />
        <meta
          name="keywords"
          content="Best Resort in Nashik, Coconut County in Nashik,Nature Resort near Nashik,Best Hotel In Nashik,Hotel Near Nashik With Pool,Resort Near Nashik,Top Resort in Nashik,Best Villa in Nashik,Resort with Deluxe Rooms in Nashik, Top Resort with Deluxe Rooms in Nashik ,Best Resort with Deluxe Rooms in Nashik,Resort with Meeting Hall in Nashik,Best Resort with Party Hall in Nashik"

        />
        <link
          rel="canonical"
          href="https://coconutcounty.in/coconut-county-nashik"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content="Coconut County in Nashik | Coconut County Resort"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://coconutcounty.in/coconut-county-nashik"
        />
        <meta
          property="og:description"
          content="Discover unparalleled luxury and tranquility at Coconut County Resort, voted the best in Nashik, for an unforgettable escape."
        />
        <meta
          property="og:image"
          content="https://coconutcounty.in/static/media/JMS.7493f202fc387288d7c0.png"
        />
      </MetaTags>
      <BreadCrumb pageName="Coconut County in Nashik" slugs="About Us" />
      <section className="container clearfix about-resort common-pad custom-padding about-info-box">
        <div className="row" bis_skin_checked={1}>
          <div
            className="col-lg-8 col-md-8 col-sm-8 col-xs-12"
            bis_skin_checked={1}
          >
            <div className="sec-header3" bis_skin_checked={1}>
              <h2>About Resort</h2>
              <h3>
                Our resort offers a luxurious escape with amenities and
                tranquility
              </h3>
            </div>
            <p>
              Welcome to our Coconut county in Nashik, where a symphony of
              luxury and nature awaits. Dive into leisure at our inviting
              swimming pool, where azure waters meet the warmth of the sun,
              creating an oasis of relaxation. As the sun sets, join the
              enchantment around our lively bonfire – a communal space for
              shared laughter and cherished moments.
            </p>
            <p>
              Immerse yourself in entertainment with a myriad of activities,
              ensuring every moment is filled with joy. From live performances
              to interactive games, our Coconut county in Nashik is a hub of
              excitement for all ages. Extend your experience outdoors to our
              expansive open lawn area, perfect for leisurely strolls, picnics,
              or engaging in various recreational activities.
            </p>
            <p className="d-none">
              Indulge in the perfect blend of tranquility and vibrant energy as
              our Coconut county in Nashik caters to your every desire. Our
              commitment to impeccable service ensures that your stay is not
              only comfortable but also memorable. From the refreshing poolside
              ambiance to the crackling bonfire glow, our Coconut county in
              Nashik beckons you to savor the richness of each moment, creating
              an unforgettable escape into a world where luxury meets leisure.
            </p>

            <ul>
              <li>
                <span>
                  {" "}
                  <GiGrassMushroom /> Awesome Nature
                </span>
              </li>
              <li>
                <span>
                  <FaAward />
                  Awaded Resort
                </span>
              </li>
              <li>
                <span>
                  <FcCustomerSupport />
                  Online Booking
                </span>
              </li>
            </ul>
          </div>
          <div className="col-sm-4 hidden-xs" bis_skin_checked={1}>
            <div className="img-cap-effect" bis_skin_checked={1}>
              <div className="img-box" bis_skin_checked={1}>
                <img src={about} alt="Coconut County in Nashik" />
                <div className="img-caption" bis_skin_checked={1} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container clearfix common-pad custom-padding activities-main about-resort">
        <div className="sec-header3" bis_skin_checked={1}>
          <h2 className="">Explore are Resorts</h2>
          <h3>
            Discover Luxurious Resorts for Your Ultimate Relaxation Experience
          </h3>
        </div>
        <div className="row" bis_skin_checked={1}>
          <div className="activities-wrapper" bis_skin_checked={1}>
            {/* One*/}
            <div className="activities-outer" bis_skin_checked={1}>
              <div className="col-md-8 col-md-push-4" bis_skin_checked={1}>
                <img
                  style={{ width: "100%" }}
                  src={resort}
                  alt="Coconut County "
                  className="img-responsive"
                />
              </div>
              <div className="col-md-4 col-md-pull-8" bis_skin_checked={1}>
                <div className="activities-content" bis_skin_checked={1}>
                  <h2>Pool</h2>
                  <p>
                    Welcome to our Coconut county in Nashik, where nature meets
                    luxury. Immerse yourself in the serenity of our
                    surroundings, crafted to provide a tranquil escape. From
                    elegant accommodations to rejuvenating amenities, your
                    experience here is designed for relaxation and exploration.
                    Discover the beauty of our resort, where every detail is
                    tailored to create a memorable stay amidst the lush
                    landscapes.
                  </p>
                  <Link to="/hotel-near-nashik-with-pool">
                  <a href="suite-room.html" className="res-btn mb-lg-3">
                    See all places <i className="fa fa-arrow-right " />
                  </a>
                  </Link>
                </div>
              </div>
            </div>

            {/* Two*/}
            {/* Three*/}
            {/* <div className="activities-outer" bis_skin_checked={1}>
        <div className="col-md-8 col-md-push-4" bis_skin_checked={1}>
          <img
            src="images/activities/3.jpg"
            alt=""
            className="img-responsive"
          />
        </div>
        <div className="col-md-4 col-md-pull-8" bis_skin_checked={1}>
          <div className="activities-content" bis_skin_checked={1}>
            <h2>Tracking</h2>
            <p>
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
              aut fugit, sed quia consequuntur magni dolores eos qui ratione
              voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem
              ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia
              non.
            </p>
            <a href="tracing.html" className="res-btn">
              See all places <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
      </div> */}
            {/* Three*/}
            {/* Four*/}
            {/* <div className="activities-outer" bis_skin_checked={1}>
        <div className="col-md-8" bis_skin_checked={1}>
          <img
            src="images/activities/4.jpg"
            alt=""
            className="img-responsive"
          />
        </div>
        <div className="col-md-4" bis_skin_checked={1}>
          <div className="activities-content" bis_skin_checked={1}>
            <h2>Spa</h2>
            <p>
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
              aut fugit, sed quia consequuntur magni dolores eos qui ratione
              voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem
              ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia
              non.
            </p>
            <a href="suite-room.html" className="res-btn">
              See all places <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
      </div> */}
            {/* Four*/}
            {/* Five*/}
            <div className="activities-outer" bis_skin_checked={1}>
              <div className="col-md-8 col-md-push-4" bis_skin_checked={1}>
                <img
                  style={{ width: "100%" }}
                  src={dining}
                  alt="Coconut in Nashik"
                  className="img-responsive"
                />
              </div>
              <div className="col-md-4 col-md-pull-8" bis_skin_checked={1}>
                <div className="activities-content" bis_skin_checked={1}>
                  <h2>Dinning</h2>
                  <p>
                    Indulge in a culinary journey at our Coconut county in
                    Nashik, where dining becomes an exquisite experience. Our
                    chefs passionately curate a menu that celebrates local
                    flavors and international delicacies. Savor the freshest
                    ingredients amidst a captivating ambiance, whether it's a
                    casual meal by the pool or a fine dining affair. Impeccable
                    service and a commitment to culinary excellence await you,
                    making each dining experience at our resort a memorable and
                    delicious affair.
                  </p>
                  <Link to="/hotel-near-nashik-with-pool">
                  <a href="suite-room.html" className="res-btn mb-lg-3">
                    See all places <i className="fa fa-arrow-right " />
                  </a>
                  </Link>
                </div>
              </div>
            </div>
            {/* Five*/}
            {/* Six*/}
            
            {/* Six*/}
          </div>
        </div>
      </section>
      <section className="our-galler-htwo clearfix common-pad">
        <div className="container clearfix">
          <div className="sec-header sec-w-header">
            <h2 className="">Our Gallery</h2>
            <h3>Pick a room that best suits your taste and budget</h3>
          </div>
        </div>
        <Gallery />
      </section>
    </>
  );
};

export default AboutUs;
