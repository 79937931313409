import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const RoomSlider = ({images}) => {
  return (
    <>
            <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[ Pagination, Navigation]}
        className="mySwiper"
      >
        {images.map((v,i)=>{
            return(
                <>
                <SwiperSlide>
                    <img src={v} alt='room' style={{width:"100%",height:"100%"}} />
                </SwiperSlide>
                </>
            )
        })}
      </Swiper>
    </>
    </>
  )
}

export default RoomSlider