import React from 'react'
import '../Css/ErrorPage.css'
const ErrorPage = () => {
  return (
    <>
        <div className="Errorpage" bis_skin_checked={1}>
  <div className="error" bis_skin_checked={1}>
    <h1 className="code" style={{ color: "#a2d002" }}>
      404
    </h1>
    <h2 className="desc" style={{ marginTop: 60, color: "white" }}>
    oops... There's something wrong.
    </h2>
    <a href="/" style={{ color: "white" }}>
      ... Back to previous page
    </a>
  </div>
</div>

    </>
  )
}

export default ErrorPage