import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import testimonialData from "../Asserts/TestimonialData.jsx";

const Testimonials = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {/* {serviceData.map((v,i)=>{ */}
        {testimonialData.map((v, i) => {
          return (
            <SwiperSlide>
              <>
                <div
                  className="owl-item cloned "
                  style={{ textAlign: "justify", marginRight: 30 }}
                  bis_skin_checked={1}
                >
                  <div className="item px-4" bis_skin_checked={1}>
                    <div className="test-cont" bis_skin_checked={1}>
                      <p id="style-4" className="testimonial-para">{v.review}</p>
                    </div>
                    <div className="test-bot d-flex gap-4" bis_skin_checked={1}>
                      <div className="tst-img" bis_skin_checked={1}>
                        <img
                          src={v.img}
                          alt=""
                          style={{ height: "40px", borderRadius: "50%" }}
                          className="img-responsive"
                        />
                      </div>
                      <div className="client_name" bis_skin_checked={1}>
                        <h5>
                          <a
                            href="#"
                            bis_skin_checked={1}
                            style={{ color: "var(--second-color)" }}
                          >
                            {v.name}
                            {/* - <span>Nescom Technology- Director</span> */}
                          </a>
                        </h5>
                        {/* <ul>
                  <li>
                    <a href="#">
                      <i className="fa fa-star" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-star" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-star" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-star" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-star" />
                    </a>
                  </li>
                </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </SwiperSlide>
          );
        })}

        {/* <SwiperSlide>
            <div
  className="testimonials-card wow fadeInUp animated"
  data-wow-duration="1500ms"
  data-wow-delay="000ms"
  style={{
    visibility: "visible",
    animationDuration: "1500ms",
    animationDelay: "0ms",
    animationName: "fadeInUp"
  }}
  bis_skin_checked={1}
>
  <div className="testimonials-card__inner" bis_skin_checked={1}>
    <div
      className="testimonials-card__top d-flex align-items-center justify-content-between"
      bis_skin_checked={1}
    >
      <i className="icon-quote testimonials-card__quote" > <RiDoubleQuotesL /></i>
      <div className="testimonials-card__rating" bis_skin_checked={1}>
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
      </div>
    </div>
    <div className="testimonials-card__content" bis_skin_checked={1}>
      Lorem ipsum dolor sit amet, consec adipiscing elit, sed do eiusmod tempor
      incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
      nostrud exercitation
    </div>
    <div
      className="testimonials-card__athour-wrap d-flex align-items-center"
      bis_skin_checked={1}
    >
      <div className="testimonials-card__image" bis_skin_checked={1}>
        <img
          src="https://www.w3schools.com/w3css/img_avatar3.png"
          alt="Robin Kaolaip"
        />
      </div>
      <div className="testimonials-card__athour-name" bis_skin_checked={1}>
        <h5 className="testimonials-card__name"> Robin Kaolaip</h5>
        <p className="testimonials-card__designation">
          Interior design, architecture
        </p>
      </div>
    </div>
  </div>
</div>
            </SwiperSlide>
            <SwiperSlide>
            <div
  className="testimonials-card wow fadeInUp animated"
  data-wow-duration="1500ms"
  data-wow-delay="000ms"
  style={{
    visibility: "visible",
    animationDuration: "1500ms",
    animationDelay: "0ms",
    animationName: "fadeInUp"
  }}
  bis_skin_checked={1}
>
  <div className="testimonials-card__inner" bis_skin_checked={1}>
    <div
      className="testimonials-card__top d-flex align-items-center justify-content-between"
      bis_skin_checked={1}
    >
      <i className="icon-quote testimonials-card__quote" > <RiDoubleQuotesL /></i>
      <div className="testimonials-card__rating" bis_skin_checked={1}>
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
      </div>
    </div>
    <div className="testimonials-card__content" bis_skin_checked={1}>
      Lorem ipsum dolor sit amet, consec adipiscing elit, sed do eiusmod tempor
      incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
      nostrud exercitation
    </div>
    <div
      className="testimonials-card__athour-wrap d-flex align-items-center"
      bis_skin_checked={1}
    >
      <div className="testimonials-card__image" bis_skin_checked={1}>
        <img
          src="https://www.w3schools.com/w3css/img_avatar3.png"
          alt="Robin Kaolaip"
        />
      </div>
      <div className="testimonials-card__athour-name" bis_skin_checked={1}>
        <h5 className="testimonials-card__name"> Robin Kaolaip</h5>
        <p className="testimonials-card__designation">
          Interior design, architecture
        </p>
      </div>
    </div>
  </div>
</div>
            </SwiperSlide>
            <SwiperSlide>
            <div
  className="testimonials-card wow fadeInUp animated"
  data-wow-duration="1500ms"
  data-wow-delay="000ms"
  style={{
    visibility: "visible",
    animationDuration: "1500ms",
    animationDelay: "0ms",
    animationName: "fadeInUp"
  }}
  bis_skin_checked={1}
>
  <div className="testimonials-card__inner" bis_skin_checked={1}>
    <div
      className="testimonials-card__top d-flex align-items-center justify-content-between"
      bis_skin_checked={1}
    >
      <i className="icon-quote testimonials-card__quote" > <RiDoubleQuotesL /></i>
      <div className="testimonials-card__rating" bis_skin_checked={1}>
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
      </div>
    </div>
    <div className="testimonials-card__content" bis_skin_checked={1}>
      Lorem ipsum dolor sit amet, consec adipiscing elit, sed do eiusmod tempor
      incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
      nostrud exercitation
    </div>
    <div
      className="testimonials-card__athour-wrap d-flex align-items-center"
      bis_skin_checked={1}
    >
      <div className="testimonials-card__image" bis_skin_checked={1}>
        <img
          src="https://www.w3schools.com/w3css/img_avatar3.png"
          alt="Robin Kaolaip"
        />
      </div>
      <div className="testimonials-card__athour-name" bis_skin_checked={1}>
        <h5 className="testimonials-card__name"> Robin Kaolaip</h5>
        <p className="testimonials-card__designation">
          Interior design, architecture
        </p>
      </div>
    </div>
  </div>
</div>
            </SwiperSlide> */}
      </Swiper>
    </>
  );
};

export default Testimonials;
