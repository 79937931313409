import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import "../../Css/Header.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../Asserts/logo-removebg-preview.png";

const Header = () => {
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
    }
  };

  window.addEventListener("scroll", changeColor);
  return (
    <div>
   
      <section className="NavSection">
        <div className="container-fluid">
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="dark"
            className={color ? "NavBar NavBar-bg" : "NavBar"}
          >
            <Container>
              <Navbar.Brand href="/">
                <img src={Logo} alt="Best Resort in Nashik" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav" className="main-nav">
                <Nav>
                  <Nav.Link href="/" className="nav-link">
                    {" "}
                    <Link to="/" className="nav-link">
                      {" "}
                      Home
                    </Link>
                  </Nav.Link>

                  <Nav.Link className="nav-link" href="/coconut-county-nashik">
                    {" "}
                    <Link className="nav-link" to="/coconut-county-nashik">
                      About Us
                    </Link>
                  </Nav.Link>

                  <Nav.Link className="nav-link" href="/top-resort-nashik">
                    {" "}
                    <Link className="nav-link" to="/top-resort-nashik">
                      Property
                    </Link>
                  </Nav.Link>

                  <Nav.Link  className="nav-link"  href="/best-hotel-nashik">                 
                    <Link  className="nav-link"  to="/best-hotel-nashik">Dinning</Link></Nav.Link>

                  <Nav.Link href="/hotel-near-nashik-with-pool" className="nav-link">
                    {" "}
                    <Link to="/hotel-near-nashik-with-pool" className="nav-link">
                      {" "}
                      Gallery
                    </Link>
                  </Nav.Link>
                  <Nav.Link className="nav-link" href="/resort-near-nashik">
                    {" "}
                    <Link className="nav-link" to="/resort-near-nashik">
                      Contact Us
                    </Link>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>

              <div className="socialLinks d-lg-flex d-none">
                <a
                  href="/resort-near-nashik"
                  // target="_blank"
                >
                  {" "}
                  <i className="fa fa-address-book" />{" "}
                  <span className="text-white">Enquiry Now</span>
                </a>
              </div>
            </Container>
          </Navbar>
        </div>
      </section>
    </div>
  );
};

export default Header;
